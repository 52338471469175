import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 420 400'
    xmlSpace='preserve'
    enableBackground='new 0 0 420 400'
    {...props}
  >
    <style>{'.banner_svg__st13{fill:#f3f6ff}.banner_svg__st108{fill:#012860}.banner_svg__st204{fill:#d4dffc}'}</style>
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter'
        filterUnits='userSpaceOnUse'
        x={43.95}
        y={239.13}
        width={242.6}
        height={140.11}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={43.95} y={239.13} width={242.6} height={140.11} id='banner_svg__SVGID_1_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_1_'
            filterUnits='userSpaceOnUse'
            x={43.95}
            y={239.13}
            width={242.6}
            height={140.11}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_2_'
          gradientUnits='userSpaceOnUse'
          x1={43.953}
          y1={309.181}
          x2={286.557}
          y2={309.181}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.333} stopColor='#c1c1c1' />
          <stop offset={0.639} stopColor='#909090' />
          <stop offset={0.87} stopColor='#717171' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M48.44 315.38l105.97 61.26c5.99 3.46 15.7 3.46 21.69 0l105.96-61.25c5.99-3.46 5.99-9.07 0-12.53l-105.87-61.13c-5.99-3.46-15.71-3.46-21.7-.01L48.45 302.85c-5.99 3.46-6 9.07-.01 12.53z'
          mask='url(#banner_svg__SVGID_1_)'
          fill='url(#banner_svg__SVGID_2_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={43.953}
      y1={309.181}
      x2={286.557}
      y2={309.181}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.404} stopColor='#f4f7ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M48.44 315.38l105.97 61.26c5.99 3.46 15.7 3.46 21.69 0l105.96-61.25c5.99-3.46 5.99-9.07 0-12.53l-105.87-61.13c-5.99-3.46-15.71-3.46-21.7-.01L48.45 302.85c-5.99 3.46-6 9.07-.01 12.53z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_2_'
        filterUnits='userSpaceOnUse'
        x={312.39}
        y={179.36}
        width={81.84}
        height={47.26}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={312.39} y={179.36} width={81.84} height={47.26} id='banner_svg__SVGID_4_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_2_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_3_'
            filterUnits='userSpaceOnUse'
            x={312.39}
            y={179.36}
            width={81.84}
            height={47.26}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_5_'
          gradientUnits='userSpaceOnUse'
          x1={312.39}
          y1={202.987}
          x2={394.23}
          y2={202.987}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M313.9 205.08l35.75 20.66c2.02 1.17 5.3 1.17 7.32 0l35.75-20.66c2.02-1.17 2.02-3.06 0-4.23L357 180.23c-2.02-1.17-5.3-1.17-7.32 0l-35.77 20.62c-2.02 1.17-2.03 3.06-.01 4.23z'
          mask='url(#banner_svg__SVGID_4_)'
          fill='url(#banner_svg__SVGID_5_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={312.39}
      y1={202.987}
      x2={394.23}
      y2={202.987}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M313.9 205.08l35.75 20.66c2.02 1.17 5.3 1.17 7.32 0l35.75-20.66c2.02-1.17 2.02-3.06 0-4.23L357 180.23c-2.02-1.17-5.3-1.17-7.32 0l-35.77 20.62c-2.02 1.17-2.03 3.06-.01 4.23z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_4_'
        filterUnits='userSpaceOnUse'
        x={251.59}
        y={312.44}
        width={102.68}
        height={59.3}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={251.59} y={312.44} width={102.68} height={59.3} id='banner_svg__SVGID_7_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_4_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_5_'
            filterUnits='userSpaceOnUse'
            x={251.59}
            y={312.44}
            width={102.68}
            height={59.3}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_8_'
          gradientUnits='userSpaceOnUse'
          x1={251.59}
          y1={342.09}
          x2={354.275}
          y2={342.09}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M253.49 344.71l44.85 25.93c2.54 1.47 6.65 1.47 9.18 0l44.85-25.93c2.54-1.47 2.53-3.84 0-5.3l-44.81-25.87c-2.54-1.46-6.65-1.47-9.19 0l-44.88 25.88c-2.53 1.45-2.53 3.83 0 5.29z'
          mask='url(#banner_svg__SVGID_7_)'
          fill='url(#banner_svg__SVGID_8_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={251.59}
      y1={342.09}
      x2={354.275}
      y2={342.09}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M253.49 344.71l44.85 25.93c2.54 1.47 6.65 1.47 9.18 0l44.85-25.93c2.54-1.47 2.53-3.84 0-5.3l-44.81-25.87c-2.54-1.46-6.65-1.47-9.19 0l-44.88 25.88c-2.53 1.45-2.53 3.83 0 5.29z'
      fill='url(#banner_svg__SVGID_9_)'
    />
    <linearGradient
      id='banner_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={297.169}
      y1={102.305}
      x2={398.454}
      y2={102.305}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M297.17 40.86c.54-.33 1.21-.46 1.86-.3.23.05.46.14.68.27l1 .58 92.12 53.2c1.61.93 2.6 2.65 2.6 4.51v64.61c0 .42-.08.81-.21 1.16-.19.49-.5.92-.89 1.25l2.53-1.67.02-.01c.92-.54 1.57-1.52 1.57-2.74V97.1c0-1.86-.99-3.58-2.6-4.51l-93.13-53.78c-.94-.54-2.02-.39-2.77.2l-2.78 1.85z'
      fill='url(#banner_svg__SVGID_10_)'
    />
    <path
      className='banner_svg__st13'
      d='M295.97 108.31c0 2.15 1.15 4.14 3.01 5.21l91.71 52.95c1.25.72 2.69.48 3.65-.34.38-.33.7-.75.89-1.25.13-.35.21-.74.21-1.16v-64.6c0-1.86-.99-3.58-2.6-4.51l-92.12-53.2-1-.58a2.3 2.3 0 00-.68-.27c-.65-.16-1.32-.03-1.86.3l-.04.03c-.34.22-.62.51-.83.87-.21.36-.32.77-.32 1.22l-.02 65.33z'
    />
    <linearGradient
      id='banner_svg__SVGID_11_'
      gradientUnits='userSpaceOnUse'
      x1={367.351}
      y1={110.344}
      x2={384.543}
      y2={110.344}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M367.35 98.48v19.93l17.19 3.8c-.57-5.94-3.39-12.38-8.11-17.42-2.83-3.03-5.97-5.14-9.08-6.31z'
      fill='url(#banner_svg__SVGID_11_)'
    />
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={355.478}
      y1={127.848}
      x2={367.351}
      y2={127.848}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M367.35 137.29v-18.87l-11.87 9.13c.83 1.2 1.76 2.34 2.77 3.43 2.84 3.02 5.99 5.14 9.1 6.31z'
      fill='url(#banner_svg__SVGID_12_)'
    />
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={350.051}
      y1={108.86}
      x2={367.351}
      y2={108.86}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M350.28 114.59c.04.3.09.6.14.9l16.93 2.92-11.89-19.1c-1.07.73-2.02 1.68-2.83 2.84-2.28 3.28-3 7.74-2.35 12.44z'
      fill='url(#banner_svg__SVGID_13_)'
    />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={355.457}
      y1={107.916}
      x2={367.351}
      y2={107.916}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path d='M355.46 99.31l11.89 19.1V98.48c-4.36-1.63-8.65-1.39-11.89.83z' fill='url(#banner_svg__SVGID_14_)' />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={367.351}
      y1={128.375}
      x2={384.65}
      y2={128.375}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M367.35 118.41v18.87c5.79 2.17 11.45 1.02 14.72-3.69 2.11-3.04 2.89-7.08 2.47-11.39l-17.19-3.79z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={350.422}
      y1={121.516}
      x2={367.351}
      y2={121.516}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M350.42 115.49c.61 3.56 2.01 7.23 4.1 10.61.3.49.62.97.95 1.44l11.87-9.13-16.92-2.92z'
      fill='url(#banner_svg__SVGID_16_)'
    />
    <linearGradient
      id='banner_svg__SVGID_17_'
      gradientUnits='userSpaceOnUse'
      x1={305.001}
      y1={69.867}
      x2={341.179}
      y2={69.867}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M341.18 76.76l-.01 6.44c0 1.09-1.18 1.78-2.13 1.23l-32.75-18.92a2.57 2.57 0 01-1.29-2.23v-7.12c0-.81.88-1.32 1.58-.91l33.27 19.21c.82.47 1.33 1.35 1.33 2.3z'
      fill='url(#banner_svg__SVGID_17_)'
    />
    <linearGradient
      id='banner_svg__SVGID_18_'
      gradientUnits='userSpaceOnUse'
      x1={304.992}
      y1={98.37}
      x2={341.17}
      y2={98.37}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M341.17 93.29v30.12c0 1.65-1.78 2.68-3.21 1.87L307 107.69a3.975 3.975 0 01-2.01-3.45V72.79c0-1.24 1.34-2.01 2.41-1.39l31.69 18.3c1.29.74 2.08 2.11 2.08 3.59z'
      fill='url(#banner_svg__SVGID_18_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_6_'
        filterUnits='userSpaceOnUse'
        x={313.69}
        y={85.32}
        width={18.79}
        height={11.14}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={313.69} y={85.32} width={18.79} height={11.14} id='banner_svg__SVGID_19_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_6_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_7_'
            filterUnits='userSpaceOnUse'
            x={313.69}
            y={85.32}
            width={18.79}
            height={11.14}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_20_'
          gradientUnits='userSpaceOnUse'
          x1={313.691}
          y1={90.885}
          x2={332.48}
          y2={90.885}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M332.12 96.45c-.06 0-.12-.02-.18-.05l-18.08-10.43a.348.348 0 01-.13-.48c.1-.17.31-.23.49-.13l18.08 10.43c.17.1.23.31.13.48-.06.12-.18.18-.31.18z'
          mask='url(#banner_svg__SVGID_19_)'
          fill='url(#banner_svg__SVGID_20_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_21_'
      gradientUnits='userSpaceOnUse'
      x1={313.691}
      y1={90.885}
      x2={332.48}
      y2={90.885}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M332.12 96.45c-.06 0-.12-.02-.18-.05l-18.08-10.43a.348.348 0 01-.13-.48c.1-.17.31-.23.49-.13l18.08 10.43c.17.1.23.31.13.48-.06.12-.18.18-.31.18z'
      fill='url(#banner_svg__SVGID_21_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_8_'
        filterUnits='userSpaceOnUse'
        x={309.17}
        y={87.92}
        width={27.84}
        height={16.37}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={309.17} y={87.92} width={27.84} height={16.37} id='banner_svg__SVGID_22_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_8_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_9_'
            filterUnits='userSpaceOnUse'
            x={309.17}
            y={87.92}
            width={27.84}
            height={16.37}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_23_'
          gradientUnits='userSpaceOnUse'
          x1={309.168}
          y1={96.111}
          x2={337.007}
          y2={96.111}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M336.65 104.3c-.06 0-.12-.02-.18-.05l-27.13-15.66a.36.36 0 01-.13-.49c.1-.17.32-.23.49-.13l27.13 15.66c.17.1.23.32.13.49-.07.11-.19.18-.31.18z'
          mask='url(#banner_svg__SVGID_22_)'
          fill='url(#banner_svg__SVGID_23_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_24_'
      gradientUnits='userSpaceOnUse'
      x1={309.168}
      y1={96.111}
      x2={337.007}
      y2={96.111}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M336.65 104.3c-.06 0-.12-.02-.18-.05l-27.13-15.66a.36.36 0 01-.13-.49c.1-.17.32-.23.49-.13l27.13 15.66c.17.1.23.32.13.49-.07.11-.19.18-.31.18z'
      fill='url(#banner_svg__SVGID_24_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_10_'
        filterUnits='userSpaceOnUse'
        x={309.16}
        y={93.14}
        width={27.84}
        height={16.38}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={309.16} y={93.14} width={27.84} height={16.38} id='banner_svg__SVGID_25_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_10_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_11_'
            filterUnits='userSpaceOnUse'
            x={309.16}
            y={93.14}
            width={27.84}
            height={16.38}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_26_'
          gradientUnits='userSpaceOnUse'
          x1={309.163}
          y1={101.326}
          x2={337.007}
          y2={101.326}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M336.65 109.51c-.06 0-.12-.02-.18-.05L309.34 93.8a.36.36 0 01-.13-.49c.1-.17.32-.23.49-.13l27.13 15.66a.36.36 0 01-.18.67z'
          mask='url(#banner_svg__SVGID_25_)'
          fill='url(#banner_svg__SVGID_26_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_27_'
      gradientUnits='userSpaceOnUse'
      x1={309.163}
      y1={101.326}
      x2={337.007}
      y2={101.326}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M336.65 109.51c-.06 0-.12-.02-.18-.05L309.34 93.8a.36.36 0 01-.13-.49c.1-.17.32-.23.49-.13l27.13 15.66a.36.36 0 01-.18.67z'
      fill='url(#banner_svg__SVGID_27_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_12_'
        filterUnits='userSpaceOnUse'
        x={308.56}
        y={98.01}
        width={28.45}
        height={16.73}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={308.56} y={98.01} width={28.45} height={16.73} id='banner_svg__SVGID_28_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_12_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_13_'
            filterUnits='userSpaceOnUse'
            x={308.56}
            y={98.01}
            width={28.45}
            height={16.73}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_29_'
          gradientUnits='userSpaceOnUse'
          x1={308.556}
          y1={106.378}
          x2={337.007}
          y2={106.378}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M336.65 114.74c-.06 0-.12-.02-.18-.05l-27.74-16.02a.36.36 0 01-.13-.49c.1-.17.32-.23.49-.13l27.74 16.02a.36.36 0 01-.18.67z'
          mask='url(#banner_svg__SVGID_28_)'
          fill='url(#banner_svg__SVGID_29_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_30_'
      gradientUnits='userSpaceOnUse'
      x1={308.556}
      y1={106.378}
      x2={337.007}
      y2={106.378}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M336.65 114.74c-.06 0-.12-.02-.18-.05l-27.74-16.02a.36.36 0 01-.13-.49c.1-.17.32-.23.49-.13l27.74 16.02a.36.36 0 01-.18.67z'
      fill='url(#banner_svg__SVGID_30_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_14_'
        filterUnits='userSpaceOnUse'
        x={373.24}
        y={138.08}
        width={44.4}
        height={52.28}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={373.24} y={138.08} width={44.4} height={52.28} id='banner_svg__SVGID_31_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_14_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_15_'
            filterUnits='userSpaceOnUse'
            x={373.24}
            y={138.08}
            width={44.4}
            height={52.28}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_32_'
          gradientUnits='userSpaceOnUse'
          x1={373.237}
          y1={164.221}
          x2={417.635}
          y2={164.221}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M402.26 190.36c-6.27 0-12.99-3.37-18.62-9.39a39.518 39.518 0 01-4.72-6.17c-2.86-4.61-4.72-9.66-5.38-14.6-.87-6.22.2-11.89 3.03-15.96 2.55-3.68 6.28-5.79 10.78-6.12 6.62-.48 13.86 2.92 19.87 9.34 4.98 5.32 8.47 12.06 9.82 18.96 1.36 6.95.38 13.26-2.74 17.75-2.56 3.68-6.29 5.81-10.8 6.13-.41.05-.83.06-1.24.06zm-25.2-45.76l.51.35c-2.65 3.81-3.65 9.17-2.82 15.09.64 4.78 2.44 9.66 5.21 14.12 1.33 2.14 2.87 4.15 4.57 5.97 5.67 6.06 12.73 9.41 18.88 8.96 4.12-.3 7.54-2.24 9.88-5.61 6.1-8.77 2.97-24.55-6.97-35.17-5.67-6.06-12.73-9.4-18.89-8.96-4.12.3-7.53 2.23-9.86 5.59l-.51-.34z'
          mask='url(#banner_svg__SVGID_31_)'
          fill='url(#banner_svg__SVGID_32_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_33_'
      gradientUnits='userSpaceOnUse'
      x1={373.237}
      y1={164.221}
      x2={417.635}
      y2={164.221}
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M402.26 190.36c-6.27 0-12.99-3.37-18.62-9.39a39.518 39.518 0 01-4.72-6.17c-2.86-4.61-4.72-9.66-5.38-14.6-.87-6.22.2-11.89 3.03-15.96 2.55-3.68 6.28-5.79 10.78-6.12 6.62-.48 13.86 2.92 19.87 9.34 4.98 5.32 8.47 12.06 9.82 18.96 1.36 6.95.38 13.26-2.74 17.75-2.56 3.68-6.29 5.81-10.8 6.13-.41.05-.83.06-1.24.06zm-25.2-45.76l.51.35c-2.65 3.81-3.65 9.17-2.82 15.09.64 4.78 2.44 9.66 5.21 14.12 1.33 2.14 2.87 4.15 4.57 5.97 5.67 6.06 12.73 9.41 18.88 8.96 4.12-.3 7.54-2.24 9.88-5.61 6.1-8.77 2.97-24.55-6.97-35.17-5.67-6.06-12.73-9.4-18.89-8.96-4.12.3-7.53 2.23-9.86 5.59l-.51-.34z'
      fill='url(#banner_svg__SVGID_33_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_16_'
        filterUnits='userSpaceOnUse'
        x={379.2}
        y={145.55}
        width={32.11}
        height={37.81}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={379.2} y={145.55} width={32.11} height={37.81} id='banner_svg__SVGID_34_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_16_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_17_'
            filterUnits='userSpaceOnUse'
            x={379.2}
            y={145.55}
            width={32.11}
            height={37.81}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_35_'
          gradientUnits='userSpaceOnUse'
          x1={379.196}
          y1={164.458}
          x2={411.306}
          y2={164.458}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M410.02 162.78l-.87.53c1.81 5.55 1.58 11.19-1.06 15-1.68 2.41-4.13 3.8-7.08 4.02-4.26.31-9.13-1.92-13.13-5.99l-.87.54c4.01 4.16 8.75 6.48 13.17 6.48.3 0 .6-.01.9-.03 3.27-.24 5.97-1.78 7.83-4.45 2.85-4.1 3.08-10.17 1.11-16.1zm-19.73-16.23c4.23 0 8.94 2.37 12.78 6.47.55.58 1.06 1.19 1.55 1.81l.87-.52c-.53-.68-1.09-1.34-1.69-1.98-4.35-4.64-9.59-7.11-14.38-6.76-3.27.24-5.97 1.77-7.82 4.44-2.04 2.94-2.82 7.04-2.19 11.55.32 2.35 1.01 4.73 2.03 7.04l.87-.52c-.96-2.18-1.61-4.44-1.91-6.66-.14-.98-.21-1.93-.21-2.85 0-3.11.76-5.88 2.23-7.99 1.67-2.41 4.12-3.79 7.07-4.01.27-.01.53-.02.8-.02z'
          mask='url(#banner_svg__SVGID_34_)'
          fill='url(#banner_svg__SVGID_35_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_36_'
      gradientUnits='userSpaceOnUse'
      x1={379.196}
      y1={164.458}
      x2={411.306}
      y2={164.458}
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M410.02 162.78l-.87.53c1.81 5.55 1.58 11.19-1.06 15-1.68 2.41-4.13 3.8-7.08 4.02-4.26.31-9.13-1.92-13.13-5.99l-.87.54c4.01 4.16 8.75 6.48 13.17 6.48.3 0 .6-.01.9-.03 3.27-.24 5.97-1.78 7.83-4.45 2.85-4.1 3.08-10.17 1.11-16.1zm-19.73-16.23c4.23 0 8.94 2.37 12.78 6.47.55.58 1.06 1.19 1.55 1.81l.87-.52c-.53-.68-1.09-1.34-1.69-1.98-4.35-4.64-9.59-7.11-14.38-6.76-3.27.24-5.97 1.77-7.82 4.44-2.04 2.94-2.82 7.04-2.19 11.55.32 2.35 1.01 4.73 2.03 7.04l.87-.52c-.96-2.18-1.61-4.44-1.91-6.66-.14-.98-.21-1.93-.21-2.85 0-3.11.76-5.88 2.23-7.99 1.67-2.41 4.12-3.79 7.07-4.01.27-.01.53-.02.8-.02z'
      fill='url(#banner_svg__SVGID_36_)'
    />
    <linearGradient
      id='banner_svg__SVGID_37_'
      gradientUnits='userSpaceOnUse'
      x1={389.789}
      y1={165.424}
      x2={398.574}
      y2={165.424}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M390.44 161.43c-.58.83-.76 1.96-.59 3.16.13.97.5 1.99 1.08 2.92.27.44.59.85.95 1.24 2.07 2.21 4.77 2.5 6.05.67 1.27-1.83.64-5.11-1.43-7.31-2.07-2.21-4.78-2.51-6.06-.68z'
      fill='url(#banner_svg__SVGID_37_)'
    />
    <linearGradient
      id='banner_svg__SVGID_38_'
      gradientUnits='userSpaceOnUse'
      x1={394.906}
      y1={163.477}
      x2={399.834}
      y2={163.477}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M395.27 161.24c-.32.47-.43 1.1-.33 1.77.07.55.28 1.12.6 1.64.15.24.33.48.53.69 1.16 1.24 2.68 1.4 3.39.37.71-1.03.36-2.87-.8-4.1-1.15-1.23-2.67-1.4-3.39-.37z'
      fill='url(#banner_svg__SVGID_38_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_18_'
        filterUnits='userSpaceOnUse'
        x={280.66}
        y={46.73}
        width={30.61}
        height={36.04}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={280.66} y={46.73} width={30.61} height={36.04} id='banner_svg__SVGID_39_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_18_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_19_'
            filterUnits='userSpaceOnUse'
            x={280.66}
            y={46.73}
            width={30.61}
            height={36.04}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_40_'
          gradientUnits='userSpaceOnUse'
          x1={280.662}
          y1={64.752}
          x2={311.269}
          y2={64.752}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M300.67 82.77c-4.32 0-8.95-2.32-12.84-6.47-1.21-1.3-2.31-2.73-3.25-4.25-1.97-3.18-3.25-6.66-3.71-10.07-.6-4.29.14-8.2 2.09-11 1.76-2.53 4.33-3.99 7.43-4.22 4.56-.33 9.56 2.02 13.7 6.44 3.44 3.67 5.84 8.31 6.77 13.07.94 4.79.27 9.14-1.89 12.24-1.76 2.54-4.34 4-7.45 4.23-.28.02-.57.03-.85.03zM283.3 51.22l.35.24c-1.82 2.63-2.51 6.32-1.94 10.4.44 3.29 1.68 6.66 3.59 9.73.92 1.48 1.98 2.86 3.15 4.12 3.91 4.18 8.77 6.49 13.01 6.18 2.84-.21 5.2-1.54 6.81-3.87 4.2-6.05 2.05-16.92-4.8-24.24-3.91-4.18-8.78-6.48-13.02-6.18-2.84.21-5.19 1.54-6.8 3.86l-.35-.24z'
          mask='url(#banner_svg__SVGID_39_)'
          fill='url(#banner_svg__SVGID_40_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_41_'
      gradientUnits='userSpaceOnUse'
      x1={280.662}
      y1={64.752}
      x2={311.269}
      y2={64.752}
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M300.67 82.77c-4.32 0-8.95-2.32-12.84-6.47-1.21-1.3-2.31-2.73-3.25-4.25-1.97-3.18-3.25-6.66-3.71-10.07-.6-4.29.14-8.2 2.09-11 1.76-2.53 4.33-3.99 7.43-4.22 4.56-.33 9.56 2.02 13.7 6.44 3.44 3.67 5.84 8.31 6.77 13.07.94 4.79.27 9.14-1.89 12.24-1.76 2.54-4.34 4-7.45 4.23-.28.02-.57.03-.85.03zM283.3 51.22l.35.24c-1.82 2.63-2.51 6.32-1.94 10.4.44 3.29 1.68 6.66 3.59 9.73.92 1.48 1.98 2.86 3.15 4.12 3.91 4.18 8.77 6.49 13.01 6.18 2.84-.21 5.2-1.54 6.81-3.87 4.2-6.05 2.05-16.92-4.8-24.24-3.91-4.18-8.78-6.48-13.02-6.18-2.84.21-5.19 1.54-6.8 3.86l-.35-.24z'
      fill='url(#banner_svg__SVGID_41_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_20_'
        filterUnits='userSpaceOnUse'
        x={284.77}
        y={51.88}
        width={22.14}
        height={26.07}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={284.77} y={51.88} width={22.14} height={26.07} id='banner_svg__SVGID_42_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_20_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_21_'
            filterUnits='userSpaceOnUse'
            x={284.77}
            y={51.88}
            width={22.14}
            height={26.07}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_43_'
          gradientUnits='userSpaceOnUse'
          x1={284.77}
          y1={64.915}
          x2={306.906}
          y2={64.915}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M306.02 63.76l-.6.37c1.25 3.83 1.09 7.72-.73 10.34-1.16 1.66-2.84 2.62-4.88 2.77-2.94.21-6.29-1.32-9.05-4.13l-.6.37c2.77 2.87 6.03 4.47 9.08 4.47.21 0 .41-.01.62-.02 2.25-.16 4.12-1.22 5.4-3.07 1.96-2.83 2.12-7.02.76-11.1zm-13.6-11.19c2.91 0 6.17 1.64 8.81 4.46.38.4.73.82 1.07 1.25l.6-.36c-.36-.47-.75-.93-1.16-1.36-3-3.2-6.61-4.9-9.91-4.66-2.25.16-4.11 1.22-5.39 3.06-1.41 2.03-1.95 4.86-1.51 7.96.22 1.62.69 3.26 1.4 4.85l.6-.36c-.66-1.51-1.11-3.06-1.31-4.59-.09-.67-.14-1.33-.14-1.97 0-2.14.53-4.05 1.54-5.5 1.15-1.66 2.84-2.62 4.87-2.76.16-.01.34-.02.53-.02z'
          mask='url(#banner_svg__SVGID_42_)'
          fill='url(#banner_svg__SVGID_43_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_44_'
      gradientUnits='userSpaceOnUse'
      x1={284.77}
      y1={64.915}
      x2={306.906}
      y2={64.915}
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M306.02 63.76l-.6.37c1.25 3.83 1.09 7.72-.73 10.34-1.16 1.66-2.84 2.62-4.88 2.77-2.94.21-6.29-1.32-9.05-4.13l-.6.37c2.77 2.87 6.03 4.47 9.08 4.47.21 0 .41-.01.62-.02 2.25-.16 4.12-1.22 5.4-3.07 1.96-2.83 2.12-7.02.76-11.1zm-13.6-11.19c2.91 0 6.17 1.64 8.81 4.46.38.4.73.82 1.07 1.25l.6-.36c-.36-.47-.75-.93-1.16-1.36-3-3.2-6.61-4.9-9.91-4.66-2.25.16-4.11 1.22-5.39 3.06-1.41 2.03-1.95 4.86-1.51 7.96.22 1.62.69 3.26 1.4 4.85l.6-.36c-.66-1.51-1.11-3.06-1.31-4.59-.09-.67-.14-1.33-.14-1.97 0-2.14.53-4.05 1.54-5.5 1.15-1.66 2.84-2.62 4.87-2.76.16-.01.34-.02.53-.02z'
      fill='url(#banner_svg__SVGID_44_)'
    />
    <linearGradient
      id='banner_svg__SVGID_45_'
      gradientUnits='userSpaceOnUse'
      x1={292.073}
      y1={65.581}
      x2={298.129}
      y2={65.581}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M292.52 62.83c-.4.57-.53 1.35-.41 2.18.09.67.34 1.37.74 2.01.19.3.4.59.65.85 1.42 1.52 3.29 1.73 4.17.46.88-1.26.44-3.52-.99-5.04-1.41-1.52-3.28-1.73-4.16-.46z'
      fill='url(#banner_svg__SVGID_45_)'
    />
    <linearGradient
      id='banner_svg__SVGID_46_'
      gradientUnits='userSpaceOnUse'
      x1={295.6}
      y1={64.239}
      x2={298.997}
      y2={64.239}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M295.85 62.69c-.22.32-.29.76-.23 1.22.05.38.19.77.42 1.13.1.17.23.33.37.48.8.85 1.85.97 2.34.26.49-.71.25-1.98-.55-2.83-.81-.85-1.85-.96-2.35-.26z'
      fill='url(#banner_svg__SVGID_46_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_22_'
        filterUnits='userSpaceOnUse'
        x={157.61}
        y={313.23}
        width={111.37}
        height={59.16}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={157.61} y={313.23} width={111.37} height={59.16} id='banner_svg__SVGID_47_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_22_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_23_'
            filterUnits='userSpaceOnUse'
            x={157.61}
            y={313.23}
            width={111.37}
            height={59.16}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_48_'
          gradientUnits='userSpaceOnUse'
          x1={155.633}
          y1={342.447}
          x2={268.203}
          y2={342.447}
          gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M268.98 347.51l-5.32 7.08-.26.34-3.24 4.3-12.32-2.54c-2.12 1.46-4.53 2.76-7.17 3.9l4.71 5.8.54.67-3.06.72-18.72 4.36-5.29-6.52c-3.31.24-6.69.29-10.08.11l-5.01 6.67-20.26-4.17-.82-.17-.92-.19.15-.2 4.86-6.46c-2.7-1.09-5.11-2.32-7.22-3.68l-12.37 2.88-3.64-4.48-5.68-7.01 4.99-1.16 7.28-1.7c-.45-1.77-.52-3.58-.18-5.4l-7.28-1.5-5.05-1.04 8.82-11.71 12.19 2.51c.47-.34.97-.66 1.47-.98.17-.12.34-.22.52-.33.65-.39 1.33-.78 2.04-1.16 1.01-.54 2.07-1.05 3.17-1.53l-.62-.76-4.53-5.58 21.79-5.08 5.09 6.29c1.32-.1 2.66-.17 4.01-.22 1.83-.06 3.67-.05 5.53.03.31.01.63.02.94.04l1.35-1.8.1-.13.46-.61 2.89-3.85 4.43.91 17.57 3.62-4.81 6.4c1.52.61 2.95 1.27 4.28 1.97 1.13.59 2.19 1.22 3.18 1.87l11.92-2.78 9.32 11.49-6.21 1.45-5.81 1.35c.43 1.8.47 3.64.07 5.49l6.43 1.33 5.77 1.16z'
          mask='url(#banner_svg__SVGID_47_)'
          fill='url(#banner_svg__SVGID_48_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_49_'
      gradientUnits='userSpaceOnUse'
      x1={155.633}
      y1={342.447}
      x2={268.203}
      y2={342.447}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.999} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M268.98 347.51l-5.32 7.08-.26.34-3.24 4.3-12.32-2.54c-2.12 1.46-4.53 2.76-7.17 3.9l4.71 5.8.54.67-3.06.72-18.72 4.36-5.29-6.52c-3.31.24-6.69.29-10.08.11l-5.01 6.67-20.26-4.17-.82-.17-.92-.19.15-.2 4.86-6.46c-2.7-1.09-5.11-2.32-7.22-3.68l-12.37 2.88-3.64-4.48-5.68-7.01 4.99-1.16 7.28-1.7c-.45-1.77-.52-3.58-.18-5.4l-7.28-1.5-5.05-1.04 8.82-11.71 12.19 2.51c.47-.34.97-.66 1.47-.98.17-.12.34-.22.52-.33.65-.39 1.33-.78 2.04-1.16 1.01-.54 2.07-1.05 3.17-1.53l-.62-.76-4.53-5.58 21.79-5.08 5.09 6.29c1.32-.1 2.66-.17 4.01-.22 1.83-.06 3.67-.05 5.53.03.31.01.63.02.94.04l1.35-1.8.1-.13.46-.61 2.89-3.85 4.43.91 17.57 3.62-4.81 6.4c1.52.61 2.95 1.27 4.28 1.97 1.13.59 2.19 1.22 3.18 1.87l11.92-2.78 9.32 11.49-6.21 1.45-5.81 1.35c.43 1.8.47 3.64.07 5.49l6.43 1.33 5.77 1.16z'
      fill='url(#banner_svg__SVGID_49_)'
    />
    <linearGradient
      id='banner_svg__SVGID_50_'
      gradientUnits='userSpaceOnUse'
      x1={155.633}
      y1={329.033}
      x2={268.204}
      y2={329.033}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M269.82 334.02l-5.32 7.08-.26.34-3.24 4.3-12.32-2.54c-2.12 1.46-4.53 2.76-7.17 3.9l4.71 5.8.54.67-3.06.72-18.72 4.36-5.29-6.52c-3.31.24-6.69.29-10.08.11l-5.01 6.67-20.26-4.17-.82-.17-.92-.19.15-.2 4.86-6.46c-2.7-1.09-5.11-2.32-7.22-3.68l-12.37 2.88-3.64-4.48-5.68-7.01 4.99-1.16 7.28-1.7c-.45-1.77-.52-3.58-.18-5.4l-7.28-1.5-5.05-1.04 8.82-11.71 12.19 2.51c.47-.34.97-.66 1.47-.98.17-.12.34-.22.52-.33.65-.39 1.33-.78 2.04-1.16 1.01-.54 2.07-1.05 3.17-1.53l-.62-.76-4.53-5.58 21.79-5.08 5.09 6.29c1.32-.1 2.66-.17 4.01-.22 1.83-.06 3.67-.05 5.53.03.31.01.63.02.94.04l1.35-1.8.1-.13.46-.61 2.89-3.85 4.43.91 17.57 3.62-4.81 6.4c1.52.61 2.95 1.27 4.28 1.97 1.13.59 2.19 1.22 3.18 1.87l11.92-2.78 9.32 11.49-6.21 1.45-5.81 1.35c.43 1.8.47 3.64.07 5.49l6.43 1.33 5.77 1.16z'
      fill='url(#banner_svg__SVGID_50_)'
    />
    <linearGradient
      id='banner_svg__SVGID_51_'
      gradientUnits='userSpaceOnUse'
      x1={192.936}
      y1={337.001}
      x2={228.19}
      y2={337.001}
      gradientTransform='matrix(.9928 .0169 -.0274 1.0067 14.229 -13.368)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M230.29 332.79c-3.6 4.78-13.79 7.16-22.76 5.31s-13.33-7.22-9.74-12c3.6-4.78 13.79-7.16 22.76-5.31 8.98 1.85 13.34 7.22 9.74 12z'
      fill='url(#banner_svg__SVGID_51_)'
    />
    <path d='M246.22 352.89l1.93-1.24c-.62.43-1.27.84-1.93 1.24z' fill='none' stroke='#000' strokeMiterlimit={10} />
    <linearGradient
      id='banner_svg__SVGID_52_'
      gradientUnits='userSpaceOnUse'
      x1={177.59}
      y1={311.388}
      x2={183.161}
      y2={311.388}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path
      d='M186.66 311.42c-1.1.48-2.16.99-3.17 1.53-.7.37-1.38.76-2.03 1.16-.17.11-.35.21-.52.33l.59-9.35 5.13 6.33z'
      fill='url(#banner_svg__SVGID_52_)'
    />
    <linearGradient
      id='banner_svg__SVGID_53_'
      gradientUnits='userSpaceOnUse'
      x1={237.625}
      y1={306.689}
      x2={242.061}
      y2={306.689}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path d='M245.69 304.27l-.52 8.37a44.96 44.96 0 00-4.29-1.97l4.81-6.4z' fill='url(#banner_svg__SVGID_53_)' />
    <linearGradient
      id='banner_svg__SVGID_54_'
      gradientUnits='userSpaceOnUse'
      x1={255.376}
      y1={324.954}
      x2={267.287}
      y2={324.954}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path
      d='M269.59 323.21l-.53 8.46-4.99 1.16-6.43-1.33c.4-1.85.36-3.69-.07-5.49l5.81-1.35 6.21-1.45z'
      fill='url(#banner_svg__SVGID_54_)'
    />
    <linearGradient
      id='banner_svg__SVGID_55_'
      gradientUnits='userSpaceOnUse'
      x1={260.077}
      y1={340.983}
      x2={268.203}
      y2={340.983}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_55_)' d='M269.82 334.02l-.53 8.45-8.81 11.72.53-8.46 3.23-4.3z' />
    <linearGradient
      id='banner_svg__SVGID_56_'
      gradientUnits='userSpaceOnUse'
      x1={247.52}
      y1={346.092}
      x2={260.077}
      y2={346.092}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_56_)' d='M261.01 345.73l-.53 8.46-12.33-2.54.53-8.45z' />
    <linearGradient
      id='banner_svg__SVGID_57_'
      gradientUnits='userSpaceOnUse'
      x1={240.561}
      y1={345.826}
      x2={247.521}
      y2={345.826}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M248.68 343.2l-.53 8.46-1.93 1.24-4.7-5.8c2.63-1.15 5.04-2.45 7.16-3.9z'
      fill='url(#banner_svg__SVGID_57_)'
    />
    <linearGradient
      id='banner_svg__SVGID_58_'
      gradientUnits='userSpaceOnUse'
      x1={224.66}
      y1={358.632}
      x2={246.248}
      y2={358.632}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_58_)' d='M246.76 353.56l-.53 8.45-21.79 5.09.53-8.46 18.73-4.37z' />
    <linearGradient
      id='banner_svg__SVGID_59_'
      gradientUnits='userSpaceOnUse'
      x1={218.926}
      y1={358.637}
      x2={224.661}
      y2={358.637}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_59_)' d='M224.97 358.64l-.53 8.46-5.29-6.53.53-8.45z' />
    <linearGradient
      id='banner_svg__SVGID_60_'
      gradientUnits='userSpaceOnUse'
      x1={208.802}
      y1={355.868}
      x2={218.927}
      y2={355.868}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M219.68 352.12l-.53 8.45c-3.31.25-6.69.29-10.08.12l.53-8.45c3.39.17 6.77.12 10.08-.12z'
      fill='url(#banner_svg__SVGID_60_)'
    />
    <linearGradient
      id='banner_svg__SVGID_61_'
      gradientUnits='userSpaceOnUse'
      x1={204.183}
      y1={359.629}
      x2={208.802}
      y2={359.629}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_61_)' d='M209.6 352.24l-.53 8.45-5.01 6.67.53-8.46z' />
    <linearGradient
      id='banner_svg__SVGID_62_'
      gradientUnits='userSpaceOnUse'
      x1={178.906}
      y1={350.364}
      x2={186.4}
      y2={350.364}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M187.61 347.71l-4.86 6.46c-1.02-.54-1.98-1.1-2.89-1.68l.53-8.46c2.1 1.36 4.52 2.6 7.22 3.68z'
      fill='url(#banner_svg__SVGID_62_)'
    />
    <linearGradient
      id='banner_svg__SVGID_63_'
      gradientUnits='userSpaceOnUse'
      x1={181.778}
      y1={361.403}
      x2={204.183}
      y2={361.403}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_63_)' d='M204.59 358.9l-.53 8.46-22-4.53.53-8.46.92.19.83.17z' />
    <linearGradient
      id='banner_svg__SVGID_64_'
      gradientUnits='userSpaceOnUse'
      x1={166.649}
      y1={351.366}
      x2={178.907}
      y2={351.366}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_64_)' d='M180.39 344.03l-.53 8.45-12.38 2.89.53-8.45z' />
    <linearGradient
      id='banner_svg__SVGID_65_'
      gradientUnits='userSpaceOnUse'
      x1={156.551}
      y1={347.677}
      x2={166.649}
      y2={347.677}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_65_)' d='M168.01 346.92l-.53 8.45-9.32-11.49.53-8.45 5.69 7z' />
    <linearGradient
      id='banner_svg__SVGID_66_'
      gradientUnits='userSpaceOnUse'
      x1={155.635}
      y1={331.968}
      x2={168.711}
      y2={331.968}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path
      d='M170.97 332.56l-7.28 1.7-5.75-1.18.53-8.46 5.05 1.04 7.28 1.5c-.36 1.82-.29 3.63.17 5.4z'
      fill='url(#banner_svg__SVGID_66_)'
    />
    <linearGradient
      id='banner_svg__SVGID_67_'
      gradientUnits='userSpaceOnUse'
      x1={177.592}
      y1={318.621}
      x2={268.204}
      y2={318.621}
      gradientTransform='matrix(.9912 .053 -.063 1.0055 24.814 -12.754)'
    >
      <stop offset={0} stopColor='#d4e3ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M269.82 334.02l-5.32 7.08c-24.63-23.47-54.25-29.96-78.45-30.44l-4.53-5.58 21.79-5.08 5.09 6.29c1.32-.1 2.66-.17 4.01-.22 1.83-.06 3.67-.05 5.53.03.31.01.63.02.94.04l1.35-1.8.1-.13.46-.61 2.89-3.85 4.43.91 17.57 3.62-4.81 6.4c1.52.61 2.95 1.27 4.28 1.97 1.13.59 2.19 1.22 3.18 1.87l11.92-2.78 9.32 11.49-6.21 1.45-5.81 1.35c.43 1.8.47 3.64.07 5.49l6.43 1.33 5.77 1.17z'
      fill='url(#banner_svg__SVGID_67_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_24_'
        filterUnits='userSpaceOnUse'
        x={59.59}
        y={140.72}
        width={308.24}
        height={181.09}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={59.59} y={140.72} width={308.24} height={181.09} id='banner_svg__SVGID_68_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_24_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_25_'
            filterUnits='userSpaceOnUse'
            x={59.59}
            y={140.72}
            width={308.24}
            height={181.09}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <radialGradient id='banner_svg__SVGID_69_' cx={213.716} cy={231.262} r={126.396} gradientUnits='userSpaceOnUse'>
          <stop offset={0} stopColor='#1a1a1a' />
          <stop offset={0.271} stopColor='#393939' />
          <stop offset={0.544} stopColor='#525252' />
          <stop offset={0.794} stopColor='#616161' />
          <stop offset={1} stopColor='#666' />
        </radialGradient>
        <path
          d='M367.84 231.26v.09c0 .13 0 .27-.01.4v.05c-.04.75-.18 1.5-.41 2.22-.4 1.25-1.1 2.42-2.09 3.38-.4.4-.86.78-1.35 1.1-.13.09-.26.17-.39.25l-4.13 2.38-134.54 77.68c-6.91 4-15.45 4-22.37 0L70.29 242.45l-6.49-3.74c-.64-.36-1.2-.8-1.7-1.3-.99-.95-1.68-2.1-2.09-3.33a8.78 8.78 0 01-.42-2.33v-.49c.04-2.83 1.44-5.6 4.21-7.22l139.39-80.48a21.223 21.223 0 0121.22 0l139.18 80.42a8.425 8.425 0 014.25 7.28z'
          mask='url(#banner_svg__SVGID_68_)'
          fill='url(#banner_svg__SVGID_69_)'
        />
      </g>
    </mask>
    <radialGradient id='banner_svg__SVGID_70_' cx={213.716} cy={231.262} r={126.396} gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#003aaa' />
      <stop offset={0.032} stopColor='#0e45af' />
      <stop offset={0.19} stopColor='#4d75c4' />
      <stop offset={0.346} stopColor='#839fd6' />
      <stop offset={0.496} stopColor='#b0c2e5' />
      <stop offset={0.639} stopColor='#d2dcf0' />
      <stop offset={0.775} stopColor='#ebeff8' />
      <stop offset={0.898} stopColor='#fafbfd' />
      <stop offset={1} stopColor='#fff' />
    </radialGradient>
    <path
      d='M367.84 231.26v.09c0 .13 0 .27-.01.4v.05c-.04.75-.18 1.5-.41 2.22-.4 1.25-1.1 2.42-2.09 3.38-.4.4-.86.78-1.35 1.1-.13.09-.26.17-.39.25l-4.13 2.38-134.54 77.68c-6.91 4-15.45 4-22.37 0L70.29 242.45l-6.49-3.74c-.64-.36-1.2-.8-1.7-1.3-.99-.95-1.68-2.1-2.09-3.33a8.78 8.78 0 01-.42-2.33v-.49c.04-2.83 1.44-5.6 4.21-7.22l139.39-80.48a21.223 21.223 0 0121.22 0l139.18 80.42a8.425 8.425 0 014.25 7.28z'
      fill='url(#banner_svg__SVGID_70_)'
    />
    <path
      className='banner_svg__st13'
      d='M367.84 174.09v.09c0 .13 0 .27-.01.4v.05c-.04.75-.18 1.5-.41 2.22-.17.53-.4 1.05-.67 1.54a8.03 8.03 0 01-1.41 1.85c-.4.4-.86.78-1.35 1.1-.13.09-.26.17-.39.25l-4.13 2.38-134.54 77.68c-6.91 4-15.45 4-22.37 0L70.29 185.28l-6.49-3.74c-.64-.36-1.2-.8-1.7-1.3-.99-.95-1.68-2.1-2.09-3.33a8.78 8.78 0 01-.42-2.33v-.54c.05-2.82 1.46-5.56 4.21-7.17L203.19 86.4a21.223 21.223 0 0121.22 0l139.18 80.42c2.8 1.61 4.23 4.44 4.25 7.27z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_26_'
        filterUnits='userSpaceOnUse'
        x={59.59}
        y={83.55}
        width={308.24}
        height={94.84}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={59.59} y={83.55} width={308.24} height={94.84} id='banner_svg__SVGID_71_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_26_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_27_'
            filterUnits='userSpaceOnUse'
            x={59.59}
            y={83.55}
            width={308.24}
            height={94.84}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_72_'
          gradientUnits='userSpaceOnUse'
          x1={59.594}
          y1={130.976}
          x2={367.839}
          y2={130.976}
        >
          <stop offset={0} />
          <stop offset={0.002} />
          <stop offset={0.395} stopColor='#1c1c1c' />
          <stop offset={0.741} stopColor='#2d2d2d' />
          <stop offset={0.999} stopColor='#333' />
        </linearGradient>
        <path
          d='M367.84 174.09v.09c0 .13 0 .27-.01.4v.05c-.04.75-.18 1.5-.41 2.22-.17.53-.4 1.05-.67 1.54-111.98-9.24-269.66-5.4-307.15-4.35.05-2.82 1.46-5.56 4.21-7.17L203.19 86.4a21.223 21.223 0 0121.22 0l139.18 80.42c2.8 1.61 4.23 4.44 4.25 7.27z'
          mask='url(#banner_svg__SVGID_71_)'
          fill='url(#banner_svg__SVGID_72_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_73_'
      gradientUnits='userSpaceOnUse'
      x1={59.594}
      y1={130.976}
      x2={367.839}
      y2={130.976}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.318} stopColor='#d6e9ff' />
      <stop offset={0.707} stopColor='#c5e1ff' />
      <stop offset={0.999} stopColor='#bfdeff' />
    </linearGradient>
    <path
      d='M367.84 174.09v.09c0 .13 0 .27-.01.4v.05c-.04.75-.18 1.5-.41 2.22-.17.53-.4 1.05-.67 1.54-111.98-9.24-269.66-5.4-307.15-4.35.05-2.82 1.46-5.56 4.21-7.17L203.19 86.4a21.223 21.223 0 0121.22 0l139.18 80.42c2.8 1.61 4.23 4.44 4.25 7.27z'
      fill='url(#banner_svg__SVGID_73_)'
    />
    <path
      d='M246.15 171.89c0 1.22-.18 2.41-.52 3.57-.11.41-.25.81-.41 1.2-3.59 9-17.07 15.69-33.12 15.69-14.87 0-27.52-5.73-32.15-13.73-.38-.64-.69-1.3-.96-1.97-.16-.4-.3-.8-.42-1.21-.34-1.15-.52-2.34-.52-3.57 0-11.31 15.24-20.47 34.05-20.47 2.82 0 5.57.2 8.19.59 14.86 2.25 25.86 10.29 25.86 19.9z'
      fill='#053772'
    />
    <linearGradient
      id='banner_svg__SVGID_74_'
      gradientUnits='userSpaceOnUse'
      x1={59.594}
      y1={222.293}
      x2={367.839}
      y2={222.293}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M367.84 179.46v.08c0 2.21-.83 4.43-2.51 6.07-.51.51-1.09.95-1.75 1.34l-4.13 2.38L224.92 267c-6.91 4-15.45 4-22.37 0L70.29 190.65l-6.49-3.74c-.64-.37-1.21-.81-1.7-1.3-1.59-1.54-2.43-3.58-2.51-5.67v-5.36c.03.79.17 1.58.42 2.33.41 1.24 1.1 2.38 2.09 3.33h.01c.49.5 1.06.94 1.7 1.3l6.49 3.74 132.26 76.36c6.92 4 15.45 4 22.37 0l134.54-77.68 4.13-2.38c.13-.08.26-.16.39-.25.5-.32.95-.69 1.35-1.1.99-.96 1.68-2.14 2.09-3.38.23-.72.37-1.47.41-2.22v4.41c-.01.14 0 .28 0 .42z'
      fill='url(#banner_svg__SVGID_74_)'
    />
    <linearGradient
      id='banner_svg__SVGID_75_'
      gradientUnits='userSpaceOnUse'
      x1={185.107}
      y1={106.668}
      x2={239.469}
      y2={106.668}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M233.47 97.04c-.11 1.08-.27 2.12-.44 3.16-.24 1.54-.52 3.03-.82 4.47-.92 4.5-2.1 8.58-3.48 12.18-.55 1.42-1.13 2.78-1.73 4.05-4.21 8.87-9.66 13.93-14.92 13.93s-10.71-5.06-14.9-13.93a82.46 82.46 0 01-4.53 1.77c4.93 10.27 11.76 16.53 19.43 16.53 7.68 0 14.51-6.27 19.43-16.54.63-1.29 1.21-2.61 1.76-4.01 1.73-4.36 3.13-9.29 4.18-14.64.27-1.47.53-2.99.76-4.53.39-2.51.68-5.1.87-7.75a130.85 130.85 0 01-5.61 5.31zm5.97-17.52c-1.64-1.79-3.4-3.59-5.29-5.39.18 2.56.26 5.2.26 7.92 0 2.71-.08 5.35-.26 7.91 1.89-1.8 3.64-3.6 5.29-5.39.02-.84.03-1.68.03-2.51 0-.86-.02-1.7-.03-2.54zm-47.48 25.15c-.31-1.44-.58-2.93-.81-4.47-.18-1.03-.34-2.1-.45-3.17-1.95-1.76-3.82-3.52-5.6-5.28.19 2.65.48 5.24.87 7.74.21 1.54.47 3.06.76 4.53 1.05 5.36 2.45 10.3 4.16 14.65 1.47-.53 2.98-1.15 4.55-1.82-1.37-3.6-2.56-7.68-3.48-12.18z'
      fill='url(#banner_svg__SVGID_75_)'
    />
    <linearGradient
      id='banner_svg__SVGID_76_'
      gradientUnits='userSpaceOnUse'
      x1={184.72}
      y1={57.429}
      x2={239.082}
      y2={57.429}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M239.08 72.36a137.41 137.41 0 00-5.61-5.31c-.13-1.06-.27-2.12-.44-3.14-.24-1.54-.52-3.03-.82-4.48-.92-4.48-2.1-8.56-3.48-12.17a60.02 60.02 0 00-1.73-4.07c-4.21-8.86-9.66-13.93-14.92-13.93s-10.71 5.07-14.9 13.93c-.61 1.27-1.19 2.63-1.74 4.07-1.37 3.6-2.56 7.68-3.48 12.17-.31 1.45-.58 2.95-.81 4.48-.18 1.03-.32 2.08-.45 3.16a96.05 96.05 0 00-.68 7.06c-.18 2.56-.26 5.21-.26 7.94 0 2.72.08 5.36.26 7.92-1.87-1.82-3.64-3.62-5.27-5.39-.02-.84-.03-1.68-.03-2.53 0-.85.02-1.7.03-2.54.05-2.43.16-4.83.35-7.17.19-2.65.48-5.24.87-7.74.21-1.54.47-3.05.76-4.51 1.03-5.36 2.45-10.28 4.16-14.65.56-1.4 1.15-2.74 1.76-4.02 4.93-10.27 11.76-16.53 19.43-16.53 7.68 0 14.51 6.27 19.43 16.54.63 1.29 1.21 2.63 1.76 4.01 1.73 4.37 3.13 9.29 4.18 14.65.29 1.47.53 2.98.76 4.51.39 2.5.68 5.08.87 7.74z'
      fill='url(#banner_svg__SVGID_76_)'
    />
    <linearGradient
      id='banner_svg__SVGID_77_'
      gradientUnits='userSpaceOnUse'
      x1={179.382}
      y1={62.619}
      x2={228.729}
      y2={62.619}
    >
      <stop offset={0} stopColor='#ffaaac' />
      <stop offset={1} stopColor='#ff0007' />
    </linearGradient>
    <path
      d='M179.38 78.38c.97 1.2 2 2.43 3.08 3.67.73-.84 1.5-1.69 2.29-2.54.05-2.43.16-4.83.35-7.17-2.02 2.01-3.94 4.04-5.72 6.04zm28.47-24.67c-2.31 1.55-4.63 3.2-6.95 4.96-2.03 1.54-4.06 3.16-6.1 4.85a150 150 0 00-2.06 1.76c-.69.6-1.37 1.2-2.03 1.79a96.05 96.05 0 00-.68 7.06c1.97-1.9 4.06-3.83 6.27-5.74 2.23-1.93 4.45-3.74 6.64-5.45 2.1-1.63 4.16-3.14 6.21-4.57.98-.68 1.97-1.34 2.94-1.98-1.44-.95-2.84-1.85-4.24-2.68zM227 43.19c-4.79 2.03-9.8 4.64-14.9 7.8 1.4.87 2.81 1.77 4.23 2.72 4.32-2.61 8.5-4.76 12.4-6.45a55.98 55.98 0 00-1.73-4.07z'
      fill='url(#banner_svg__SVGID_77_)'
    />
    <linearGradient
      id='banner_svg__SVGID_78_'
      gradientUnits='userSpaceOnUse'
      x1={161.442}
      y1={82.042}
      x2={262.732}
      y2={82.042}
    >
      <stop offset={0} stopColor='#ffaaac' />
      <stop offset={1} stopColor='#ff0007' />
    </linearGradient>
    <path
      d='M258.98 65.22c-.6 1.29-1.27 2.58-2.05 3.91-2.32 4.15-5.35 8.49-9 12.92-1 1.22-2.05 2.44-3.13 3.67-1.79 2-3.69 4.01-5.72 6.01a137.41 137.41 0 01-7.63 7.08c-.69.6-1.39 1.19-2.06 1.76-2.03 1.69-4.06 3.31-6.1 4.85a137.92 137.92 0 01-6.97 4.96c-1.42.95-2.84 1.86-4.24 2.72-3.45 2.14-6.87 4.02-10.19 5.64-1.6.78-3.16 1.49-4.71 2.15a82.46 82.46 0 01-4.53 1.77c-5.93 2.12-11.4 3.24-16.05 3.24-4.63 0-8.47-1.1-11.18-3.45-5.42-4.69-5.14-13.31-.24-23.6.6-1.27 1.29-2.58 2.05-3.91 2.32-4.14 5.35-8.48 9-12.91 1 1.22 2.05 2.44 3.14 3.67-3.02 3.74-5.5 7.35-7.47 10.74-.77 1.36-1.47 2.65-2.08 3.93-4.27 8.84-4.55 15.74-.82 18.97 3.71 3.23 11.69 2.99 21.88-.7 1.47-.53 2.98-1.15 4.55-1.82 3.92-1.7 8.08-3.86 12.4-6.47 1.4-.84 2.82-1.73 4.24-2.67.97-.64 1.95-1.3 2.93-1.98 2.05-1.43 4.11-2.95 6.21-4.57 2.19-1.7 4.42-3.52 6.64-5.45 2.23-1.93 4.32-3.84 6.27-5.76 1.89-1.8 3.64-3.6 5.29-5.39.79-.84 1.55-1.68 2.27-2.51 1.1-1.24 2.13-2.47 3.1-3.69 3.02-3.74 5.5-7.35 7.45-10.73.79-1.36 1.48-2.67 2.1-3.94 2.27-4.72 3.42-8.9 3.42-12.25 0-2.92-.87-5.21-2.6-6.72-1.74-1.51-4.4-2.25-7.77-2.25-3.87 0-8.68.99-14.13 2.96-.55-1.38-1.13-2.72-1.76-4.01 11.85-4.26 21.8-4.5 27.22.2 5.45 4.73 5.17 13.35.27 23.63z'
      fill='url(#banner_svg__SVGID_78_)'
    />
    <linearGradient
      id='banner_svg__SVGID_79_'
      gradientUnits='userSpaceOnUse'
      x1={161.434}
      y1={82.042}
      x2={262.732}
      y2={82.042}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M165.43 41.62c-2.71 2.35-4 5.67-4 9.68 0 4.04 1.29 8.76 3.76 13.91.6 1.27 1.29 2.58 2.05 3.91 1.45-.53 3.02-1.02 4.68-1.51-.77-1.34-1.47-2.65-2.08-3.93-4.27-8.83-4.55-15.74-.82-18.97 3.71-3.23 11.69-2.99 21.88.71.56-1.4 1.15-2.74 1.76-4.02-11.87-4.23-21.81-4.47-27.23.22zm93.55 57.25c-.6-1.27-1.27-2.58-2.05-3.91-2.32-4.14-5.35-8.48-9-12.91-1 1.22-2.05 2.44-3.13 3.67 3.02 3.74 5.48 7.35 7.45 10.73.77 1.36 1.48 2.67 2.1 3.94 4.26 8.83 4.55 15.74.82 18.97-3.73 3.23-11.71 2.99-21.9-.71-1.47-.53-2.98-1.13-4.55-1.8-3.92-1.7-8.08-3.86-12.4-6.47-1.42.95-2.84 1.86-4.24 2.72 5.11 3.14 10.13 5.77 14.92 7.8 1.53.66 3.05 1.24 4.52 1.76 5.93 2.14 11.4 3.26 16.05 3.26s8.48-1.1 11.18-3.45c5.41-4.7 5.13-13.32.23-23.6z'
      fill='url(#banner_svg__SVGID_79_)'
    />
    <linearGradient
      id='banner_svg__SVGID_80_'
      gradientUnits='userSpaceOnUse'
      x1={195.444}
      y1={62.619}
      x2={244.807}
      y2={62.619}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M244.81 78.36c-.97 1.22-2 2.44-3.1 3.69-.73-.84-1.48-1.68-2.27-2.53-1.64-1.79-3.4-3.59-5.29-5.39a143.87 143.87 0 00-6.27-5.76c-2.23-1.93-4.45-3.74-6.66-5.45a134.72 134.72 0 00-6.21-4.57 125.324 125.324 0 00-7.16-4.65c-4.32-2.61-8.48-4.76-12.4-6.45.55-1.44 1.13-2.79 1.74-4.07 4.77 2.03 9.8 4.65 14.92 7.8a145.514 145.514 0 0111.2 7.68c2.03 1.54 4.06 3.16 6.1 4.85a150 150 0 012.06 1.76c.68.59 1.35 1.19 2.02 1.77 1.97 1.76 3.84 3.53 5.61 5.31 2.01 2.02 3.92 4.02 5.71 6.01z'
      fill='url(#banner_svg__SVGID_80_)'
    />
    <linearGradient
      id='banner_svg__SVGID_81_'
      gradientUnits='userSpaceOnUse'
      x1={167.239}
      y1={89.002}
      x2={212.087}
      y2={89.002}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M212.09 107.72c-1.42.94-2.84 1.83-4.24 2.67-2.31-1.55-4.63-3.2-6.95-4.96-2.03-1.54-4.06-3.16-6.1-4.85a150 150 0 01-2.06-1.76c-.69-.6-1.37-1.19-2.03-1.79-1.95-1.76-3.82-3.52-5.6-5.28a137.56 137.56 0 01-5.72-6.02c-1.1-1.23-2.14-2.46-3.14-3.67-3.64-4.43-6.66-8.77-9-12.92 1.45-.53 3.02-1.02 4.68-1.51 1.97 3.39 4.45 7.01 7.47 10.76.97 1.2 2 2.43 3.08 3.67.74.84 1.5 1.68 2.29 2.53 1.63 1.77 3.4 3.58 5.27 5.39 1.97 1.9 4.06 3.83 6.27 5.74 2.23 1.93 4.45 3.74 6.66 5.45 2.08 1.62 4.14 3.14 6.21 4.57.96.68 1.94 1.33 2.91 1.98z'
      fill='url(#banner_svg__SVGID_81_)'
    />
    <linearGradient
      id='banner_svg__SVGID_82_'
      gradientUnits='userSpaceOnUse'
      x1={173.738}
      y1={139.205}
      x2={185.027}
      y2={139.205}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <circle cx={179.38} cy={139.2} r={5.64} fill='url(#banner_svg__SVGID_82_)' />
    <linearGradient
      id='banner_svg__SVGID_83_'
      gradientUnits='userSpaceOnUse'
      x1={239.163}
      y1={24.878}
      x2={250.451}
      y2={24.878}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <circle cx={244.81} cy={24.88} r={5.64} fill='url(#banner_svg__SVGID_83_)' />
    <linearGradient
      id='banner_svg__SVGID_84_'
      gradientUnits='userSpaceOnUse'
      x1={71.988}
      y1={247.397}
      x2={100.372}
      y2={247.397}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_84_)'
      d='M72.62 293.02l-.63-1.1 15.75-9.06V208.7l12-6.92.63 1.09L89 209.43v74.16z'
    />
    <linearGradient
      id='banner_svg__SVGID_85_'
      gradientUnits='userSpaceOnUse'
      x1={76.68}
      y1={250.009}
      x2={104.726}
      y2={250.009}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_85_)'
      d='M77.33 295.73l-.65-1.08 15.44-9.27v-74.15l11.97-6.94.64 1.09-11.35 6.58v74.14z'
    />
    <linearGradient
      id='banner_svg__SVGID_86_'
      gradientUnits='userSpaceOnUse'
      x1={115.441}
      y1={272.575}
      x2={143.825}
      y2={272.575}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_86_)'
      d='M116.07 318.2l-.63-1.1 15.76-9.06v-74.16l11.99-6.93.64 1.1-11.37 6.56v74.16z'
    />
    <linearGradient
      id='banner_svg__SVGID_87_'
      gradientUnits='userSpaceOnUse'
      x1={120.134}
      y1={275.186}
      x2={148.18}
      y2={275.186}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_87_)'
      d='M120.78 320.9l-.65-1.08 15.44-9.26v-74.15l11.98-6.94.63 1.09-11.35 6.58v74.14z'
    />
    <linearGradient
      id='banner_svg__SVGID_88_'
      gradientUnits='userSpaceOnUse'
      x1={321.442}
      y1={250.696}
      x2={349.826}
      y2={250.696}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_88_)'
      d='M349.2 296.32l-16.39-9.43v-74.16l-11.37-6.56.63-1.09 12 6.92v74.16l15.76 9.06z'
    />
    <linearGradient
      id='banner_svg__SVGID_89_'
      gradientUnits='userSpaceOnUse'
      x1={317.087}
      y1={253.307}
      x2={345.134}
      y2={253.307}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_89_)'
      d='M344.49 299.02l-16.06-9.62v-74.14l-11.34-6.58.63-1.09 11.97 6.94v74.15l15.44 9.26z'
    />
    <linearGradient
      id='banner_svg__SVGID_90_'
      gradientUnits='userSpaceOnUse'
      x1={277.988}
      y1={275.873}
      x2={306.373}
      y2={275.873}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_90_)'
      d='M305.74 321.49l-16.38-9.42v-74.16l-11.37-6.57.63-1.09 12 6.93v74.16l15.75 9.06z'
    />
    <linearGradient
      id='banner_svg__SVGID_91_'
      gradientUnits='userSpaceOnUse'
      x1={273.634}
      y1={278.484}
      x2={301.68}
      y2={278.484}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_91_)'
      d='M301.03 324.2l-16.05-9.63v-74.14l-11.35-6.57.64-1.09 11.97 6.94v74.15l15.44 9.26z'
    />
    <path
      className='banner_svg__st108'
      d='M245.63 175.47c-.11.41-.25.81-.41 1.2-3.59 9-17.07 15.69-33.12 15.69-14.87 0-27.52-5.73-32.15-13.73-.38-.64-.69-1.3-.96-1.97-.16-.4-.3-.8-.42-1.21 2.82-9.6 16.76-16.89 33.53-16.89 2.82 0 5.57.2 8.19.59 12.97 1.94 23 8.33 25.34 16.32z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_28_'
        filterUnits='userSpaceOnUse'
        x={163.58}
        y={160.7}
        width={7.65}
        height={66.09}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={163.58} y={160.7} width={7.65} height={66.09} id='banner_svg__SVGID_92_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_28_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_29_'
            filterUnits='userSpaceOnUse'
            x={163.58}
            y={160.7}
            width={7.65}
            height={66.09}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_93_'
          gradientUnits='userSpaceOnUse'
          x1={32.232}
          y1={169.928}
          x2={32.249}
          y2={219.728}
          gradientTransform='matrix(-1 0 0 1 199.65 0)'
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_92_)'
          fill='url(#banner_svg__SVGID_93_)'
          d='M171.24 165.11l-7.64-4.41-.02 61.69 7.64 4.41z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_94_'
      gradientUnits='userSpaceOnUse'
      x1={32.232}
      y1={169.928}
      x2={32.249}
      y2={219.728}
      gradientTransform='matrix(-1 0 0 1 199.65 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_94_)' d='M171.24 165.11l-7.64-4.41-.02 61.69 7.64 4.41z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_30_'
        filterUnits='userSpaceOnUse'
        x={148.32}
        y={134}
        width={7.64}
        height={84}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={148.32} y={134} width={7.64} height={84} id='banner_svg__SVGID_95_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_30_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_31_'
            filterUnits='userSpaceOnUse'
            x={148.32}
            y={134}
            width={7.64}
            height={84}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_96_'
          gradientUnits='userSpaceOnUse'
          x1={47.605}
          y1={149.935}
          x2={47.358}
          y2={214.764}
          gradientTransform='matrix(-1 0 0 1 199.65 0)'
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_95_)'
          fill='url(#banner_svg__SVGID_96_)'
          d='M155.97 138.27l-7.64-4.27-.01 79.59 7.64 4.4z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_97_'
      gradientUnits='userSpaceOnUse'
      x1={47.605}
      y1={149.935}
      x2={47.358}
      y2={214.764}
      gradientTransform='matrix(-1 0 0 1 199.65 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_97_)' d='M155.97 138.27l-7.64-4.27-.01 79.59 7.64 4.4z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_32_'
        filterUnits='userSpaceOnUse'
        x={133.05}
        y={169.5}
        width={7.64}
        height={39.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={133.05} y={169.5} width={7.64} height={39.67} id='banner_svg__SVGID_98_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_32_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_33_'
            filterUnits='userSpaceOnUse'
            x={133.05}
            y={169.5}
            width={7.64}
            height={39.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_99_'
          gradientUnits='userSpaceOnUse'
          x1={62.314}
          y1={177.666}
          x2={63.499}
          y2={207.647}
          gradientTransform='matrix(-1 0 0 1 199.65 0)'
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_98_)'
          fill='url(#banner_svg__SVGID_99_)'
          d='M140.7 173.91l-7.64-4.41-.01 35.26 7.64 4.41z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_100_'
      gradientUnits='userSpaceOnUse'
      x1={62.314}
      y1={177.666}
      x2={63.499}
      y2={207.647}
      gradientTransform='matrix(-1 0 0 1 199.65 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_100_)' d='M140.7 173.91l-7.64-4.41-.01 35.26 7.64 4.41z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_34_'
        filterUnits='userSpaceOnUse'
        x={117.79}
        y={173.92}
        width={7.64}
        height={26.45}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={117.79} y={173.92} width={7.64} height={26.45} id='banner_svg__SVGID_101_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_34_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_35_'
            filterUnits='userSpaceOnUse'
            x={117.79}
            y={173.92}
            width={7.64}
            height={26.45}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_102_'
          gradientUnits='userSpaceOnUse'
          x1={77.803}
          y1={181.282}
          x2={78.432}
          y2={197.086}
          gradientTransform='matrix(-1 0 0 1 199.65 0)'
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          mask='url(#banner_svg__SVGID_101_)'
          fill='url(#banner_svg__SVGID_102_)'
          d='M125.44 178.32l-7.64-4.4-.01 22.04 7.64 4.41z'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_103_'
      gradientUnits='userSpaceOnUse'
      x1={77.803}
      y1={181.282}
      x2={78.432}
      y2={197.086}
      gradientTransform='matrix(-1 0 0 1 199.65 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_103_)' d='M125.44 178.32l-7.64-4.4-.01 22.04 7.64 4.41z' />
    <linearGradient
      id='banner_svg__SVGID_104_'
      gradientUnits='userSpaceOnUse'
      x1={212.283}
      y1={234.088}
      x2={249.345}
      y2={234.088}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M249.35 225.92v5.2c0 .34-.02.67-.08 1-.84 5.69-8.78 10.14-18.45 10.14-9.68 0-17.62-4.45-18.45-10.14a6.53 6.53 0 01-.08-1v-5.2c0 .22.01.44.03.66.02.22.05.44.1.66.02.11.05.22.07.32.05.21.12.42.19.64.03.1.07.21.12.31.06.15.13.31.2.46.07.15.15.3.23.44.02.03.03.07.05.1.05.09.1.17.15.25.05.09.11.17.17.26.12.18.25.36.39.54.37.49.8.96 1.28 1.4l.33.3c.13.11.26.22.39.32.08.07.17.13.25.2.07.05.13.1.2.15.09.06.17.13.26.19.13.1.27.19.41.28.1.07.2.13.31.2.12.07.24.15.36.22l.01.01.42.24c.15.09.31.17.47.25.15.08.3.15.45.23l.24.12c.19.09.37.17.57.26.39.17.79.33 1.19.48a22.153 22.153 0 001.75.56c.28.08.56.16.85.23.2.05.39.1.59.14.17.04.35.08.52.11.12.02.24.05.37.07.07.01.15.03.22.04.18.04.36.07.54.1l.2.03c.15.02.3.05.45.07.14.02.27.04.41.05.07.01.14.02.22.02.19.02.37.05.56.06.3.03.61.06.91.07a15.892 15.892 0 001.28.06c.27.01.54.01.81.01h.01c.27 0 .54 0 .81-.01.24 0 .47-.01.71-.02l.5-.03c.35-.02.7-.05 1.04-.09.15-.01.3-.03.44-.05.11-.01.22-.02.33-.04.12-.01.23-.03.34-.05.18-.02.36-.05.54-.08.02 0 .03 0 .05-.01.22-.03.44-.07.66-.11l.16-.03c.14-.02.27-.05.4-.08.16-.03.32-.07.47-.1.2-.04.39-.09.59-.14.13-.03.26-.06.38-.1.16-.04.32-.08.47-.13.19-.05.38-.11.56-.17.42-.13.83-.27 1.23-.41.41-.15.81-.31 1.19-.48.19-.08.38-.17.57-.26.1-.05.21-.1.32-.15.13-.06.25-.12.38-.19.15-.07.29-.15.43-.23.13-.07.26-.15.39-.22.05-.02.1-.05.14-.08.1-.06.21-.12.31-.19l.45-.3.3-.21c.14-.1.28-.2.41-.3.08-.06.17-.13.25-.2.12-.09.25-.19.36-.3.15-.12.29-.25.42-.38.14-.12.27-.26.4-.39s.25-.27.37-.4c.38-.43.72-.89 1.01-1.35.05-.08.1-.17.15-.25.02-.03.04-.07.05-.1.08-.15.16-.3.23-.44.07-.15.14-.3.2-.46.04-.1.08-.21.12-.31.03-.1.07-.21.1-.32.03-.1.06-.21.08-.31.03-.11.05-.22.07-.33.1-.42.15-.86.15-1.31z'
      fill='url(#banner_svg__SVGID_104_)'
    />
    <path
      className='banner_svg__st13'
      d='M249.14 227.57c-.02.1-.05.21-.09.31-.03.11-.07.22-.1.32-.04.1-.08.21-.12.31-.06.15-.13.3-.2.46-.07.15-.15.3-.23.44-.06.12-.13.24-.2.35-.29.47-.62.92-1.01 1.35-.12.14-.25.27-.37.4s-.26.26-.4.39-.28.26-.42.38l-.36.3c-.08.07-.17.14-.25.2-.13.1-.27.2-.41.3l-.3.21-.45.3c-.1.07-.2.13-.31.19-.05.03-.1.06-.14.08-.13.07-.26.15-.39.22-.14.08-.28.15-.43.23-.12.07-.25.13-.38.19-.1.05-.21.1-.32.15-.19.09-.37.17-.57.26-.39.17-.79.33-1.19.48-.4.15-.81.29-1.23.41-.18.06-.37.11-.56.17-.16.05-.31.09-.47.13-.12.04-.25.07-.38.1-.2.05-.39.1-.59.14-.16.04-.32.07-.47.1-.13.03-.27.05-.4.08-.05.01-.1.02-.16.03-.22.04-.44.08-.66.11-.02 0-.03.01-.05.01-.18.03-.36.06-.54.08-.11.02-.23.03-.34.05-.11.02-.22.03-.33.04-.15.02-.29.03-.44.05-.34.04-.69.07-1.04.09l-.5.03c-.23.01-.47.02-.71.02-.27.01-.54.01-.81.01h-.01c-.27 0-.54 0-.81-.01-.23 0-.46-.01-.69-.02-.2-.01-.4-.02-.59-.04-.31-.02-.61-.04-.91-.07l-.56-.06c-.07 0-.15-.01-.22-.02-.14-.01-.27-.03-.41-.05-.15-.02-.3-.04-.45-.07-.07 0-.13-.02-.2-.03-.18-.03-.37-.06-.54-.1-.07-.01-.15-.02-.22-.04-.12-.02-.25-.05-.37-.07-.17-.03-.35-.07-.52-.11-.2-.05-.4-.09-.59-.14-.29-.07-.57-.15-.85-.23l-.52-.15c-.42-.13-.83-.27-1.23-.41-.41-.15-.81-.31-1.19-.48-.19-.08-.38-.17-.57-.26l-.24-.12c-.15-.07-.3-.15-.45-.23-.16-.08-.31-.17-.47-.25l-.42-.24s-.01 0-.01-.01c-.12-.07-.25-.15-.36-.22-.1-.07-.21-.13-.31-.2a9.95 9.95 0 01-.41-.28c-.09-.06-.17-.12-.26-.19-.07-.05-.13-.1-.2-.15-.08-.07-.17-.13-.25-.2-.13-.1-.26-.21-.39-.32l-.33-.3c-.48-.44-.91-.91-1.28-1.4-.14-.17-.27-.36-.39-.54-.06-.09-.12-.17-.17-.26-.07-.12-.14-.23-.2-.35-.08-.15-.16-.3-.23-.44-.07-.15-.14-.3-.2-.46-.04-.1-.08-.21-.12-.31-.07-.21-.14-.42-.19-.64-.02-.11-.05-.22-.07-.32-.04-.22-.07-.44-.1-.66-.02-.22-.03-.44-.03-.66 0-6.1 8.15-11.05 18.26-11.13l-1.94 5.44c.76-.11 1.56-.17 2.38-.17 5.39 0 9.77 2.63 9.77 5.88 0 .47-.09.93-.27 1.37-.04.1-.08.19-.12.28h8.77z'
    />
    <linearGradient
      id='banner_svg__SVGID_105_'
      gradientUnits='userSpaceOnUse'
      x1={221.21}
      y1={225.919}
      x2={240.759}
      y2={225.919}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M240.76 225.92c0 .47-.09.93-.27 1.37-.04.1-.08.19-.12.28-1.19 2.44-4.94 4.22-9.38 4.22-4.61 0-8.48-1.92-9.51-4.51-.17-.44-.27-.9-.27-1.37 0-2.75 3.15-5.06 7.39-5.7.76-.11 1.56-.17 2.38-.17 5.4 0 9.78 2.63 9.78 5.88z'
      fill='url(#banner_svg__SVGID_105_)'
    />
    <linearGradient
      id='banner_svg__SVGID_106_'
      gradientUnits='userSpaceOnUse'
      x1={259.378}
      y1={206.757}
      x2={296.436}
      y2={206.757}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M296.44 198.59v5.2c0 .34-.02.67-.07 1-.84 5.69-8.79 10.14-18.46 10.14-9.67 0-17.61-4.45-18.45-10.14-.05-.32-.07-.66-.07-1v-5.2a6.632 6.632 0 00.08 1.05c.05.31.12.62.21.91.06.21.14.42.22.63.01.02.01.03.02.05.05.14.11.27.18.41.07.15.15.3.23.44.02.03.04.07.05.1.05.09.1.17.15.25.48.79 1.11 1.53 1.86 2.22.1.1.21.2.32.3.02.02.04.03.06.05.12.11.26.22.39.32.01.01.03.02.05.04.12.1.25.19.38.29.21.15.42.3.64.44.11.07.22.15.33.21.14.09.28.17.42.25.11.07.22.13.33.19.16.09.32.18.49.27.13.07.26.13.39.2.09.05.18.09.27.13.19.09.37.17.57.26.24.11.48.21.73.3a24.413 24.413 0 003.34 1.04c.17.04.33.08.5.11.08.02.17.04.26.05l.45.09c.07.01.14.02.21.04.2.04.4.07.61.1.03.01.07.01.1.02.17.03.33.05.5.07.13.02.26.04.39.05.09.01.17.02.27.03.16.02.32.04.48.05.33.04.66.06 1 .08.18.02.36.02.54.03.23.01.46.02.7.02.27.01.54.01.81.01s.54 0 .81-.01c.23 0 .47-.01.7-.02l.54-.03c.33-.02.66-.05.98-.08.17-.02.33-.03.49-.05.1-.01.2-.02.3-.04.12-.01.24-.03.36-.05.16-.02.32-.05.48-.07.05 0 .1-.01.15-.02.2-.03.41-.07.61-.1.31-.05.61-.11.9-.18.19-.04.38-.08.57-.13.04 0 .07-.01.11-.02.15-.03.29-.07.43-.11.32-.08.64-.17.95-.27.22-.07.44-.14.65-.21.64-.22 1.26-.45 1.86-.71.19-.08.38-.17.57-.26.09-.04.18-.09.27-.13.13-.06.26-.13.39-.2.17-.09.33-.17.49-.27.11-.06.22-.12.33-.19.14-.08.28-.16.42-.25.12-.07.25-.15.37-.23l.1-.07c.13-.09.26-.17.38-.26l.1-.07c.14-.1.27-.2.41-.3.02-.02.03-.03.05-.04.13-.1.26-.21.39-.32.02-.02.04-.03.06-.05.14-.12.27-.24.4-.37.14-.12.27-.25.4-.39.13-.13.26-.27.37-.4 0-.01.01-.01.02-.02.23-.26.45-.53.64-.81a10.816 10.816 0 00.5-.77c.1-.18.2-.36.28-.54.07-.15.14-.3.2-.46.08-.21.16-.42.22-.64.06-.19.11-.39.15-.58.07-.45.12-.91.12-1.38z'
      fill='url(#banner_svg__SVGID_106_)'
    />
    <path
      className='banner_svg__st13'
      d='M296.44 198.59c0 .47-.05.93-.14 1.38-.04.2-.09.39-.15.58-.06.21-.14.42-.22.64-.06.15-.13.3-.2.46-.14.3-.3.59-.49.89-.09.15-.19.29-.29.43-.2.27-.41.54-.64.81-.01.01-.02.01-.02.02-.12.14-.25.27-.37.4-.13.13-.26.26-.4.39l-.4.37c-.02.02-.04.03-.06.05-.12.11-.26.22-.39.32-.01.01-.03.02-.05.04-.13.1-.27.2-.41.3l-.1.07c-.12.09-.25.17-.38.26l-.1.07c-.12.08-.24.16-.37.23-.14.09-.28.17-.42.25-.11.07-.22.13-.33.19-.16.09-.32.18-.49.27-.13.07-.26.13-.39.2-.09.05-.18.09-.27.13-.19.09-.37.17-.57.26-.59.26-1.21.5-1.86.71-.22.07-.43.14-.65.21-.31.1-.62.19-.95.27-.14.04-.29.08-.43.11a.36.36 0 01-.11.02c-.19.05-.37.09-.57.13-.3.07-.59.12-.9.18-.2.04-.4.07-.61.1-.05.01-.1.02-.15.02-.16.02-.32.05-.48.07-.12.02-.24.03-.36.05-.1.02-.2.03-.3.04-.16.02-.32.04-.49.05-.32.03-.65.06-.98.08-.18.02-.36.02-.54.03-.23.01-.47.02-.7.02-.27.01-.54.01-.81.01s-.54 0-.81-.01c-.24 0-.47-.01-.7-.02l-.54-.03c-.34-.02-.67-.05-1-.08-.16-.01-.32-.03-.48-.05l-.27-.03c-.13-.01-.26-.03-.39-.05-.17-.02-.34-.05-.5-.07-.03 0-.07-.01-.1-.02-.2-.03-.41-.07-.61-.1-.07-.01-.14-.02-.21-.04l-.45-.09c-.09-.02-.17-.03-.26-.05-.17-.04-.34-.07-.5-.11-.84-.2-1.65-.44-2.42-.7-.31-.11-.62-.22-.92-.34-.25-.1-.49-.2-.73-.3-.19-.08-.38-.17-.57-.26-.09-.04-.18-.09-.27-.13-.13-.06-.26-.13-.39-.2-.17-.09-.33-.17-.49-.27-.11-.06-.22-.12-.33-.19-.14-.08-.28-.16-.42-.25l-.33-.21c-.22-.15-.44-.3-.64-.44-.13-.1-.26-.19-.38-.29-.02-.02-.03-.03-.05-.04-.13-.11-.27-.22-.39-.32-.02-.02-.05-.03-.06-.05-.11-.1-.22-.2-.32-.3-.75-.69-1.38-1.43-1.86-2.22-.07-.12-.14-.23-.2-.35-.08-.15-.16-.3-.23-.44-.06-.14-.12-.27-.18-.41-.01-.02-.01-.03-.02-.05-.08-.21-.16-.42-.22-.63-.09-.3-.16-.61-.21-.91l9.39.89c-.11-.18-.2-.37-.27-.57-.18-.44-.27-.9-.27-1.37 0-3.25 4.38-5.88 9.77-5.88.81 0 1.6.06 2.35.17v-5.33c9.05.73 16.02 5.39 16.02 11.03z'
    />
    <linearGradient
      id='banner_svg__SVGID_107_'
      gradientUnits='userSpaceOnUse'
      x1={268.301}
      y1={198.586}
      x2={287.85}
      y2={198.586}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M287.85 198.59c0 .47-.09.93-.27 1.37-1.03 2.58-4.9 4.51-9.51 4.51-4.27 0-7.9-1.65-9.23-3.94-.11-.18-.2-.37-.27-.57-.18-.44-.27-.9-.27-1.37 0-3.25 4.38-5.88 9.77-5.88.81 0 1.6.06 2.35.17 4.27.64 7.43 2.95 7.43 5.71z'
      fill='url(#banner_svg__SVGID_107_)'
    />
    <linearGradient
      id='banner_svg__SVGID_108_'
      gradientUnits='userSpaceOnUse'
      x1={303.256}
      y1={180.782}
      x2={340.314}
      y2={180.782}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M340.31 172.61v5.2c0 .34-.02.67-.07 1-.84 5.69-8.78 10.14-18.45 10.14-4.88 0-9.32-1.13-12.63-2.98-3.25-1.82-5.42-4.33-5.83-7.16-.05-.32-.07-.66-.07-1v-5.2c0 .22.01.45.03.67.02.22.05.44.1.65.08.44.21.86.38 1.28.01.02.01.03.02.05.05.14.11.27.18.41.07.15.15.3.23.44.02.03.04.07.05.1.03.05.06.11.1.16.05.08.1.17.15.25.35.56.79 1.09 1.28 1.6a14.902 14.902 0 00.8.75c.12.11.25.22.39.32.08.07.17.14.25.2.13.1.26.2.39.29.14.1.27.2.42.29.12.08.24.16.37.24.12.08.24.15.37.22.15.1.31.18.47.27.14.07.28.15.42.22.12.07.25.13.38.19.1.05.21.1.32.15.19.09.37.17.57.26.27.12.55.24.84.34.12.05.23.09.35.14.4.15.81.28 1.23.41.18.06.37.11.56.17.27.08.54.15.81.22.19.05.37.09.57.13h.03l.54.12c.19.04.37.08.57.11.18.04.36.07.54.1l.2.03c.15.02.3.05.45.07.13.02.27.04.41.05l.22.03.53.06c.31.03.62.06.94.07a15.892 15.892 0 001.28.06c.27.01.54.01.81.01h.01c.27 0 .54 0 .81-.01.24 0 .47-.01.71-.02l.5-.03c.35-.02.7-.05 1.04-.09.15-.01.31-.03.46-.05.22-.02.43-.05.64-.08.18-.02.36-.05.54-.08.03 0 .06-.01.09-.02.22-.03.44-.07.66-.11.04 0 .07-.01.1-.02.15-.02.3-.05.44-.09.17-.03.34-.07.5-.11.19-.04.37-.08.55-.13.15-.04.3-.07.44-.11l.53-.15c.15-.05.31-.09.46-.14.21-.06.42-.13.63-.2a22.187 22.187 0 002.36-.95c.07-.04.15-.07.22-.11.33-.16.64-.32.95-.49.1-.06.21-.12.31-.18.14-.08.28-.16.41-.25.15-.09.29-.18.43-.27.17-.11.34-.22.49-.34.15-.11.3-.22.44-.33.02-.01.03-.02.05-.04.15-.11.29-.23.43-.36.15-.12.29-.25.43-.38.14-.12.27-.26.4-.39s.25-.27.37-.4c.01 0 .01-.01.02-.02.22-.25.43-.51.62-.78.03-.04.06-.08.08-.12.08-.11.16-.23.23-.34.01-.01.02-.02.02-.04l.18-.3c.02-.03.04-.07.05-.1.07-.13.14-.26.2-.39.02-.03.03-.06.04-.08.06-.12.12-.25.17-.37.01-.02.01-.03.02-.05a7.553 7.553 0 00.37-1.22c.05-.46.1-.92.1-1.39z'
      fill='url(#banner_svg__SVGID_108_)'
    />
    <path
      className='banner_svg__st13'
      d='M340.31 172.61c0 .47-.05.93-.14 1.38-.06.31-.15.61-.25.9-.04.11-.07.21-.12.32-.01.02-.01.03-.02.05-.05.12-.1.25-.17.37-.01.02-.02.05-.04.08-.06.13-.13.27-.2.39-.07.14-.15.27-.24.4l-7.42-3.59c-.03.36-.12.71-.26 1.06-1.03 2.58-4.9 4.51-9.51 4.51-2.68 0-5.1-.65-6.86-1.7l-3.35 5.18a16.32 16.32 0 01-1.41-.6c-.11-.05-.21-.1-.32-.15-.13-.06-.25-.12-.38-.19-.14-.07-.28-.15-.42-.22-.16-.09-.32-.18-.47-.27-.12-.07-.25-.15-.37-.22s-.25-.15-.37-.24c-.14-.09-.28-.19-.42-.29-.14-.1-.27-.19-.39-.29-.08-.06-.17-.13-.25-.2-.13-.1-.26-.21-.39-.32-.14-.12-.27-.24-.4-.36-.14-.13-.27-.26-.4-.39a9.98 9.98 0 01-1.28-1.6c-.05-.08-.11-.17-.15-.25-.05-.08-.1-.17-.15-.26-.08-.15-.16-.3-.23-.44-.06-.14-.12-.27-.18-.41-.01-.02-.01-.03-.02-.05-.17-.42-.3-.84-.38-1.28-.04-.22-.07-.43-.1-.65-.02-.22-.03-.44-.03-.67 0-6.15 8.3-11.14 18.53-11.14s18.56 4.99 18.56 11.14z'
    />
    <linearGradient
      id='banner_svg__SVGID_109_'
      gradientUnits='userSpaceOnUse'
      x1={312.179}
      y1={172.614}
      x2={331.732}
      y2={172.614}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M331.73 172.61c0 .11 0 .21-.01.32-.03.36-.12.71-.26 1.06-1.03 2.58-4.9 4.51-9.51 4.51-2.68 0-5.1-.65-6.86-1.7-1.28-.76-2.21-1.72-2.64-2.81-.18-.44-.27-.9-.27-1.38 0-3.25 4.38-5.88 9.77-5.88 5.4.01 9.78 2.64 9.78 5.88z'
      fill='url(#banner_svg__SVGID_109_)'
    />
    <linearGradient
      id='banner_svg__SVGID_110_'
      gradientUnits='userSpaceOnUse'
      x1={228.603}
      y1={221.176}
      x2={249.345}
      y2={221.176}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M249.35 225.92c0 .45-.05.89-.13 1.33-.02.11-.05.22-.07.33h-8.78c.05-.1.09-.19.12-.28.18-.44.27-.9.27-1.37 0-3.25-4.38-5.88-9.77-5.88-.82 0-1.62.06-2.38.17l1.94-5.44h.27c10.22 0 18.53 4.98 18.53 11.14z'
      fill='url(#banner_svg__SVGID_110_)'
    />
    <linearGradient
      id='banner_svg__SVGID_111_'
      gradientUnits='userSpaceOnUse'
      x1={259.374}
      y1={193.983}
      x2={280.428}
      y2={193.983}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M280.43 187.55v5.33c-.75-.11-1.54-.17-2.35-.17-5.4 0-9.77 2.63-9.77 5.88 0 .47.09.93.27 1.37.07.19.17.38.27.57l-9.39-.89c0-.02-.01-.04-.01-.05-.05-.33-.07-.66-.07-1v0c0-6.15 8.3-11.14 18.53-11.14.85-.01 1.69.03 2.52.1z'
      fill='url(#banner_svg__SVGID_111_)'
    />
    <linearGradient
      id='banner_svg__SVGID_112_'
      gradientUnits='userSpaceOnUse'
      x1={311.742}
      y1={178.343}
      x2={339.142}
      y2={178.343}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M339.14 176.52c-.01.01-.02.02-.02.04-.07.12-.15.23-.23.34-.02.04-.05.08-.08.12-.19.27-.4.52-.62.78-.01.01-.01.02-.02.02-.12.14-.24.27-.37.4s-.26.26-.4.39-.28.26-.43.38c-.14.12-.28.24-.43.36-.01.01-.03.02-.05.04-.14.11-.29.22-.44.33-.16.12-.33.23-.49.34-.14.1-.28.19-.43.27-.13.09-.27.17-.41.25-.1.06-.2.12-.31.18-.31.17-.62.34-.95.49-.07.04-.15.07-.22.11-.19.09-.37.17-.57.26-.57.25-1.17.48-1.79.69-.21.07-.42.14-.63.2-.15.05-.3.09-.46.14-.17.05-.35.1-.53.15-.15.04-.29.08-.44.11-.18.05-.37.09-.55.13-.17.04-.33.07-.5.11l-.44.09c-.03.01-.07.02-.1.02-.22.04-.44.08-.66.11-.03.01-.06.01-.09.02-.18.03-.36.05-.54.08-.21.03-.42.06-.64.08-.15.02-.3.04-.46.05-.34.04-.69.07-1.04.09l-.5.03c-.23.01-.47.02-.71.02-.27.01-.54.01-.81.01h-.01c-.27 0-.54 0-.81-.01-.23 0-.46-.01-.69-.02-.2-.01-.4-.02-.59-.04-.32-.02-.63-.04-.94-.07l-.53-.06-.22-.03c-.14-.01-.27-.03-.41-.05-.15-.02-.3-.04-.45-.07-.07 0-.13-.02-.2-.03-.18-.03-.37-.06-.54-.1-.19-.03-.38-.07-.57-.11-.18-.03-.36-.07-.54-.12h-.03c-.19-.04-.38-.09-.57-.13-.27-.07-.55-.14-.81-.22-.19-.05-.38-.11-.56-.17-.42-.13-.83-.27-1.23-.41-.12-.05-.24-.09-.35-.14l3.35-5.18c1.76 1.05 4.19 1.7 6.86 1.7 4.61 0 8.48-1.92 9.51-4.51.13-.34.22-.69.26-1.06l7.43 3.6z'
      fill='url(#banner_svg__SVGID_112_)'
    />
    <linearGradient
      id='banner_svg__SVGID_113_'
      gradientUnits='userSpaceOnUse'
      x1={-72.693}
      y1={189.186}
      x2={7.593}
      y2={189.186}
      gradientTransform='matrix(-1 0 0 1 112.432 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_113_)'
      d='M184.15 219.08l.97-.56-15.43-26.72-15.2 8.78-18.94-24.05-11.23 6.49-7.4-17.07-11.52-6.66-.56.97 11.19 6.48 7.76 17.88 11.48-6.62 18.93 24.04 15.08-8.7z'
    />
    <linearGradient
      id='banner_svg__SVGID_114_'
      gradientUnits='userSpaceOnUse'
      x1={6.749}
      y1={160.085}
      x2={10.536}
      y2={160.085}
      gradientTransform='matrix(-1 0 0 1 112.432 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_114_)' d='M105.68 155.71v8.75l-3.78-6.56z' />
    <linearGradient
      id='banner_svg__SVGID_115_'
      gradientUnits='userSpaceOnUse'
      x1={-58.642}
      y1={192.572}
      x2={-55.464}
      y2={192.572}
      gradientTransform='matrix(-1 0 0 1 112.432 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M167.9 192.65c.06 1.33.82 2.38 1.7 2.33.88-.04 1.53-1.15 1.47-2.49-.06-1.33-.82-2.38-1.7-2.33-.88.04-1.53 1.16-1.47 2.49z'
      fill='url(#banner_svg__SVGID_115_)'
    />
    <linearGradient
      id='banner_svg__SVGID_116_'
      gradientUnits='userSpaceOnUse'
      x1={-43.497}
      y1={201.28}
      x2={-40.319}
      y2={201.28}
      gradientTransform='matrix(-1 0 0 1 112.432 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M152.75 201.36c.06 1.33.82 2.38 1.7 2.33.88-.04 1.53-1.15 1.47-2.49-.06-1.33-.82-2.38-1.7-2.33-.87.04-1.53 1.15-1.47 2.49z'
      fill='url(#banner_svg__SVGID_116_)'
    />
    <linearGradient
      id='banner_svg__SVGID_117_'
      gradientUnits='userSpaceOnUse'
      x1={-24.568}
      y1={177.487}
      x2={-21.39}
      y2={177.487}
      gradientTransform='matrix(-1 0 0 1 112.432 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M133.83 177.56c.06 1.33.82 2.38 1.7 2.33.88-.04 1.53-1.15 1.47-2.49-.06-1.33-.82-2.38-1.7-2.33-.88.05-1.54 1.16-1.47 2.49z'
      fill='url(#banner_svg__SVGID_117_)'
    />
    <linearGradient
      id='banner_svg__SVGID_118_'
      gradientUnits='userSpaceOnUse'
      x1={-13.211}
      y1={183.819}
      x2={-10.033}
      y2={183.819}
      gradientTransform='matrix(-1 0 0 1 112.432 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M122.47 183.89c.06 1.33.82 2.38 1.7 2.33.88-.04 1.53-1.15 1.47-2.49s-.82-2.38-1.7-2.33c-.88.05-1.53 1.16-1.47 2.49z'
      fill='url(#banner_svg__SVGID_118_)'
    />
    <path className='banner_svg__st13' d='M123.51 275.67l-59.73 34.49-59.74-34.5 59.24-34.2.5-.29z' />
    <linearGradient
      id='banner_svg__SVGID_119_'
      gradientUnits='userSpaceOnUse'
      x1={4.042}
      y1={302.757}
      x2={63.778}
      y2={302.757}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_119_)' d='M4.04 275.66v19.71l59.74 34.49v-19.7z' />
    <linearGradient
      id='banner_svg__SVGID_120_'
      gradientUnits='userSpaceOnUse'
      x1={63.778}
      y1={302.762}
      x2={123.514}
      y2={302.762}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_120_)' d='M63.78 329.86l59.73-34.49v-19.7l-59.73 34.49z' />
    <linearGradient
      id='banner_svg__SVGID_121_'
      gradientUnits='userSpaceOnUse'
      x1={44.172}
      y1={309.227}
      x2={47.404}
      y2={309.227}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M44.41 307.76c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.82-1.75-.93-2.22-.25z'
      fill='url(#banner_svg__SVGID_121_)'
    />
    <linearGradient
      id='banner_svg__SVGID_122_'
      gradientUnits='userSpaceOnUse'
      x1={49.361}
      y1={312.357}
      x2={52.593}
      y2={312.357}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M49.6 310.89c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.82-1.75-.93-2.22-.25z'
      fill='url(#banner_svg__SVGID_122_)'
    />
    <linearGradient
      id='banner_svg__SVGID_123_'
      gradientUnits='userSpaceOnUse'
      x1={54.55}
      y1={315.38}
      x2={57.782}
      y2={315.38}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M54.79 313.91c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.81-1.75-.92-2.22-.25z'
      fill='url(#banner_svg__SVGID_123_)'
    />
    <linearGradient
      id='banner_svg__SVGID_124_'
      gradientUnits='userSpaceOnUse'
      x1={13.463}
      y1={293.003}
      x2={20.234}
      y2={293.003}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M13.46 290.3v1.7c0 .21.11.41.3.51l6.02 3.48c.2.12.45-.03.45-.26v-1.67c0-.25-.14-.49-.35-.61l-5.92-3.42c-.22-.14-.5.02-.5.27z'
      fill='url(#banner_svg__SVGID_124_)'
    />
    <linearGradient
      id='banner_svg__SVGID_125_'
      gradientUnits='userSpaceOnUse'
      x1={114.981}
      y1={289.204}
      x2={118.213}
      y2={289.204}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M117.97 287.73c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69s1.75-.92 2.22-.25z'
      fill='url(#banner_svg__SVGID_125_)'
    />
    <linearGradient
      id='banner_svg__SVGID_126_'
      gradientUnits='userSpaceOnUse'
      x1={109.792}
      y1={292.334}
      x2={113.024}
      y2={292.334}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M112.78 290.87c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69.76-.82 1.75-.93 2.22-.25z'
      fill='url(#banner_svg__SVGID_126_)'
    />
    <linearGradient
      id='banner_svg__SVGID_127_'
      gradientUnits='userSpaceOnUse'
      x1={104.603}
      y1={295.357}
      x2={107.835}
      y2={295.357}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M107.59 293.89c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69.76-.82 1.76-.93 2.22-.25z'
      fill='url(#banner_svg__SVGID_127_)'
    />
    <linearGradient
      id='banner_svg__SVGID_128_'
      gradientUnits='userSpaceOnUse'
      x1={73.197}
      y1={312.652}
      x2={79.968}
      y2={312.652}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M79.97 309.95v1.7c0 .21-.11.41-.3.51l-6.02 3.48c-.2.12-.45-.03-.45-.26v-1.67c0-.25.14-.49.35-.61l5.92-3.42c.22-.14.5.02.5.27z'
      fill='url(#banner_svg__SVGID_128_)'
    />
    <linearGradient
      id='banner_svg__SVGID_129_'
      gradientUnits='userSpaceOnUse'
      x1={9.023}
      y1={274.948}
      x2={12.587}
      y2={274.948}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M12.59 272.69V276c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21v-3.31c0 .67.8 1.21 1.78 1.21s1.78-.54 1.78-1.21z'
      fill='url(#banner_svg__SVGID_129_)'
    />
    <linearGradient
      id='banner_svg__SVGID_130_'
      gradientUnits='userSpaceOnUse'
      x1={9.023}
      y1={272.689}
      x2={12.587}
      y2={272.689}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M12.59 272.69c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21c0-.67.8-1.21 1.78-1.21s1.78.54 1.78 1.21z'
      fill='url(#banner_svg__SVGID_130_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_36_'
        filterUnits='userSpaceOnUse'
        x={63.28}
        y={241.17}
        width={60.24}
        height={68.99}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={63.28} y={241.17} width={60.24} height={68.99} id='banner_svg__SVGID_131_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_36_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_37_'
            filterUnits='userSpaceOnUse'
            x={63.28}
            y={241.17}
            width={60.24}
            height={68.99}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_132_'
          gradientUnits='userSpaceOnUse'
          x1={63.276}
          y1={275.665}
          x2={123.514}
          y2={275.665}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M63.78 310.16s10.76-40.73-.5-68.7l.5-.29 59.74 34.5-59.74 34.49z'
          mask='url(#banner_svg__SVGID_131_)'
          fill='url(#banner_svg__SVGID_132_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_133_'
      gradientUnits='userSpaceOnUse'
      x1={63.276}
      y1={275.665}
      x2={123.514}
      y2={275.665}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path d='M63.78 310.16s10.76-40.73-.5-68.7l.5-.29 59.74 34.5-59.74 34.49z' fill='url(#banner_svg__SVGID_133_)' />
    <linearGradient
      id='banner_svg__SVGID_134_'
      gradientUnits='userSpaceOnUse'
      x1={116.431}
      y1={274.504}
      x2={119.995}
      y2={274.504}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M119.99 272.24v3.31c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21v-3.31c0 .67.8 1.21 1.78 1.21.99 0 1.78-.54 1.78-1.21z'
      fill='url(#banner_svg__SVGID_134_)'
    />
    <linearGradient
      id='banner_svg__SVGID_135_'
      gradientUnits='userSpaceOnUse'
      x1={116.431}
      y1={272.245}
      x2={119.995}
      y2={272.245}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M119.99 272.24c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21.8-1.21 1.78-1.21c.99 0 1.78.55 1.78 1.21z'
      fill='url(#banner_svg__SVGID_135_)'
    />
    <linearGradient
      id='banner_svg__SVGID_136_'
      gradientUnits='userSpaceOnUse'
      x1={61.996}
      y1={305.057}
      x2={65.56}
      y2={305.057}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M65.56 302.8v3.31c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21v-3.31c0 .67.8 1.21 1.78 1.21s1.78-.54 1.78-1.21z'
      fill='url(#banner_svg__SVGID_136_)'
    />
    <linearGradient
      id='banner_svg__SVGID_137_'
      gradientUnits='userSpaceOnUse'
      x1={61.996}
      y1={302.798}
      x2={65.56}
      y2={302.798}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M65.56 302.8c0 .67-.8 1.21-1.78 1.21S62 303.47 62 302.8s.8-1.21 1.78-1.21 1.78.54 1.78 1.21z'
      fill='url(#banner_svg__SVGID_137_)'
    />
    <linearGradient
      id='banner_svg__SVGID_138_'
      gradientUnits='userSpaceOnUse'
      x1={4.075}
      y1={322.474}
      x2={63.811}
      y2={322.474}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_138_)' d='M4.07 295.38v19.7l59.74 34.49v-19.69z' />
    <linearGradient
      id='banner_svg__SVGID_139_'
      gradientUnits='userSpaceOnUse'
      x1={63.811}
      y1={322.48}
      x2={123.547}
      y2={322.48}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_139_)' d='M63.81 349.57l59.74-34.48v-19.7l-59.74 34.49z' />
    <linearGradient
      id='banner_svg__SVGID_140_'
      gradientUnits='userSpaceOnUse'
      x1={44.206}
      y1={328.944}
      x2={47.437}
      y2={328.944}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M44.45 327.48c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.76-.82-1.76-.93-2.22-.25z'
      fill='url(#banner_svg__SVGID_140_)'
    />
    <linearGradient
      id='banner_svg__SVGID_141_'
      gradientUnits='userSpaceOnUse'
      x1={49.395}
      y1={332.075}
      x2={52.626}
      y2={332.075}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M49.64 330.61c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.76-.82-1.76-.93-2.22-.25z'
      fill='url(#banner_svg__SVGID_141_)'
    />
    <linearGradient
      id='banner_svg__SVGID_142_'
      gradientUnits='userSpaceOnUse'
      x1={54.583}
      y1={335.098}
      x2={57.815}
      y2={335.098}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M54.82 333.63c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.82-1.75-.93-2.22-.25z'
      fill='url(#banner_svg__SVGID_142_)'
    />
    <path
      className='banner_svg__st13'
      d='M13.5 310.02v1.7c0 .21.11.41.3.51l6.02 3.48c.2.12.45-.03.45-.26v-1.67c0-.25-.14-.49-.35-.61L14 309.75c-.23-.14-.5.02-.5.27z'
    />
    <linearGradient
      id='banner_svg__SVGID_143_'
      gradientUnits='userSpaceOnUse'
      x1={115.014}
      y1={308.921}
      x2={118.246}
      y2={308.921}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M118.01 307.45c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69.75-.81 1.75-.92 2.22-.25z'
      fill='url(#banner_svg__SVGID_143_)'
    />
    <linearGradient
      id='banner_svg__SVGID_144_'
      gradientUnits='userSpaceOnUse'
      x1={109.825}
      y1={312.051}
      x2={113.057}
      y2={312.051}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M112.82 310.58c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69.75-.81 1.75-.92 2.22-.25z'
      fill='url(#banner_svg__SVGID_144_)'
    />
    <linearGradient
      id='banner_svg__SVGID_145_'
      gradientUnits='userSpaceOnUse'
      x1={104.637}
      y1={315.075}
      x2={107.868}
      y2={315.075}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M107.63 313.61c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69.75-.82 1.75-.93 2.22-.25z'
      fill='url(#banner_svg__SVGID_145_)'
    />
    <path
      className='banner_svg__st13'
      d='M80 329.67v1.7c0 .21-.11.41-.3.51l-6.02 3.48c-.2.12-.45-.03-.45-.26v-1.67c0-.25.14-.49.35-.61l5.92-3.42c.23-.14.5.02.5.27z'
    />
    <linearGradient
      id='banner_svg__SVGID_146_'
      gradientUnits='userSpaceOnUse'
      x1={4.042}
      y1={342.163}
      x2={63.779}
      y2={342.163}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_146_)' d='M4.04 315.07v19.7l59.74 34.49v-19.7z' />
    <linearGradient
      id='banner_svg__SVGID_147_'
      gradientUnits='userSpaceOnUse'
      x1={63.779}
      y1={342.169}
      x2={123.515}
      y2={342.169}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_147_)' d='M63.78 369.26l59.73-34.48v-19.7l-59.73 34.48z' />
    <linearGradient
      id='banner_svg__SVGID_148_'
      gradientUnits='userSpaceOnUse'
      x1={44.173}
      y1={348.633}
      x2={47.405}
      y2={348.633}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M44.41 347.16c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.81-1.75-.92-2.22-.25z'
      fill='url(#banner_svg__SVGID_148_)'
    />
    <linearGradient
      id='banner_svg__SVGID_149_'
      gradientUnits='userSpaceOnUse'
      x1={49.362}
      y1={351.764}
      x2={52.594}
      y2={351.764}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M49.6 350.29c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.81-1.75-.92-2.22-.25z'
      fill='url(#banner_svg__SVGID_149_)'
    />
    <linearGradient
      id='banner_svg__SVGID_150_'
      gradientUnits='userSpaceOnUse'
      x1={54.551}
      y1={354.787}
      x2={57.783}
      y2={354.787}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M54.79 353.32c-.21.31-.28.72-.22 1.16.05.36.18.73.4 1.08.1.16.22.31.35.45.76.81 1.76.92 2.22.25.47-.67.23-1.88-.53-2.69-.75-.82-1.75-.93-2.22-.25z'
      fill='url(#banner_svg__SVGID_150_)'
    />
    <linearGradient
      id='banner_svg__SVGID_151_'
      gradientUnits='userSpaceOnUse'
      x1={13.463}
      y1={332.41}
      x2={20.235}
      y2={332.41}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M13.46 329.71v1.7c0 .21.11.41.3.51l6.02 3.48c.2.12.45-.03.45-.26v-1.67c0-.25-.14-.49-.35-.61l-5.92-3.42c-.22-.14-.5.02-.5.27z'
      fill='url(#banner_svg__SVGID_151_)'
    />
    <linearGradient
      id='banner_svg__SVGID_152_'
      gradientUnits='userSpaceOnUse'
      x1={114.982}
      y1={328.61}
      x2={118.213}
      y2={328.61}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M117.97 327.14c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69s1.75-.92 2.22-.25z'
      fill='url(#banner_svg__SVGID_152_)'
    />
    <linearGradient
      id='banner_svg__SVGID_153_'
      gradientUnits='userSpaceOnUse'
      x1={109.793}
      y1={331.741}
      x2={113.025}
      y2={331.741}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M112.78 330.27c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69s1.76-.92 2.22-.25z'
      fill='url(#banner_svg__SVGID_153_)'
    />
    <linearGradient
      id='banner_svg__SVGID_154_'
      gradientUnits='userSpaceOnUse'
      x1={104.604}
      y1={334.764}
      x2={107.836}
      y2={334.764}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M107.6 333.29c.21.31.28.72.22 1.16-.05.36-.18.73-.4 1.08-.1.16-.22.31-.35.45-.76.81-1.76.92-2.22.25-.47-.67-.23-1.88.53-2.69.75-.81 1.75-.92 2.22-.25z'
      fill='url(#banner_svg__SVGID_154_)'
    />
    <linearGradient
      id='banner_svg__SVGID_155_'
      gradientUnits='userSpaceOnUse'
      x1={73.198}
      y1={352.058}
      x2={79.969}
      y2={352.058}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M79.97 349.36v1.7c0 .21-.11.41-.3.51l-6.02 3.48c-.2.12-.45-.03-.45-.26v-1.67c0-.25.14-.49.35-.61l5.92-3.42c.23-.14.5.02.5.27z'
      fill='url(#banner_svg__SVGID_155_)'
    />
    <linearGradient
      id='banner_svg__SVGID_156_'
      gradientUnits='userSpaceOnUse'
      x1={-287.437}
      y1={191.561}
      x2={-281.936}
      y2={191.561}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M83.85 193.19s.23-2.37.87-2.34c.63.03 2.78-3.75 3.35-3.55.57.21-1.58 3.23-.84 3.6.75.37 2.61 1.82 1.61 2.81-.99.99-3.06 2.11-3.06 2.11l-2.15-1.08.22-1.55z'
      fill='url(#banner_svg__SVGID_156_)'
    />
    <path
      className='banner_svg__st108'
      d='M61.96 280.88s-1.31 4.23 2.68 4.34c2.59.07 7.98-3.61 7.75-4.4-.23-.79-5.81-.05-5.81-.05l-2.25-1.13-2.37 1.24zM54.18 277.86s-1.31 4.23 2.68 4.34c2.59.07 7.98-3.61 7.75-4.4-.23-.79-5.81-.05-5.81-.05l-2.25-1.13-2.37 1.24z'
    />
    <linearGradient
      id='banner_svg__SVGID_157_'
      gradientUnits='userSpaceOnUse'
      x1={-311.395}
      y1={263.907}
      x2={-303.044}
      y2={263.907}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M59.67 249.38l1.95 31.42s.6.73 3.29.51c2.1-.18 2.46-.64 2.46-.64l.65-30.59-1.72-3.62-6.63 2.92z'
      fill='url(#banner_svg__SVGID_157_)'
    />
    <linearGradient
      id='banner_svg__SVGID_158_'
      gradientUnits='userSpaceOnUse'
      x1={-319.178}
      y1={260.885}
      x2={-310.828}
      y2={260.885}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M51.88 246.36l1.95 31.42s.6.73 3.29.51c2.1-.18 2.46-.64 2.46-.64l.65-30.59-1.72-3.62-6.63 2.92z'
      fill='url(#banner_svg__SVGID_158_)'
    />
    <linearGradient
      id='banner_svg__SVGID_159_'
      gradientUnits='userSpaceOnUse'
      x1={-325.379}
      y1={211.911}
      x2={-312.789}
      y2={211.911}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.155} stopColor='#dae6ff' />
      <stop offset={0.481} stopColor='#c0d6ff' />
      <stop offset={0.773} stopColor='#b0ccff' />
      <stop offset={1} stopColor='#aac9ff' />
    </linearGradient>
    <path
      d='M51.56 203.17s-5.62 7.24-5.86 11.19c-.24 3.95 4.07 6.29 4.07 6.29s1.81-1.34 1.98-1.48c.17-.14 6.58-11.67 6.53-11.86-.05-.19-6.72-4.14-6.72-4.14z'
      fill='url(#banner_svg__SVGID_159_)'
    />
    <linearGradient
      id='banner_svg__SVGID_160_'
      gradientUnits='userSpaceOnUse'
      x1={-311.854}
      y1={197.587}
      x2={-305.901}
      y2={197.587}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M59.64 196.8l-.25 4 4.88-.28s.22-4.07.55-4.35l.33-.29-.38-.62-5.57-.88.44 2.42z'
      fill='url(#banner_svg__SVGID_160_)'
    />
    <linearGradient
      id='banner_svg__SVGID_161_'
      gradientUnits='userSpaceOnUse'
      x1={-310.31}
      y1={192.766}
      x2={-303.269}
      y2={192.766}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M66.8 188.8s1.02 2.36.99 4.88c-.03 2.52-.99 3.99-.99 3.99s-3.54.77-4.97-.57c-1.44-1.34-1.02-2.81-1.02-2.81l.67-2.2 1.85-4.27 1.5-.16 1.97 1.14z'
      fill='url(#banner_svg__SVGID_161_)'
    />
    <linearGradient
      id='banner_svg__SVGID_162_'
      gradientUnits='userSpaceOnUse'
      x1={-322.691}
      y1={222.294}
      x2={-283.593}
      y2={222.294}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M87.47 195.94s-9.7 16.07-11.16 17.66c-.73.79-2.28.4-3.66-.19a.3.3 0 00-.1-.04c-.03-.01-.07-.03-.1-.04-1.31-.59-2.42-1.32-2.42-1.32-.05 1.19-.11 2.41-.13 3.6 0 .21-.01.43-.01.64-.02 5.55.54 11.19 1.23 17.44.68 6.25 0 16.52 0 16.52s-.93.8-5.96 1.5-9.28-1.88-9.28-1.88l-1.53-4.35-.72 3.33s-.79.17-2.58-.33c-1.79-.51-2.68-1.22-2.68-1.22s.45-21.53 1.39-27.38c.37-2.3.51-4.18.59-5.97.13-2.78.13-5.36.63-8.96.09-.66.29-1.24.57-1.77.85-1.6 2.46-2.59 4.13-3.21 3.66-1.36 7.78-1.06 11.15.91.32.19.64.4.96.62 0 0 .04.03.12.08.63.42 3.54 2.36 5.22 3.54.29.2.55.38.75.52.41.3 1.3-.54 2.39-1.92v-.01c2.85-3.63 6.98-10.96 6.98-10.96s1.75-.03 2.9.7c1.16.74 1.32 2.49 1.32 2.49z'
      fill='url(#banner_svg__SVGID_162_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_38_'
        filterUnits='userSpaceOnUse'
        x={68.55}
        y={210.47}
        width={3.91}
        height={5.15}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={68.55} y={210.47} width={3.91} height={5.15} id='banner_svg__SVGID_163_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_38_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_39_'
            filterUnits='userSpaceOnUse'
            x={68.55}
            y={210.47}
            width={3.91}
            height={5.15}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_164_'
          gradientUnits='userSpaceOnUse'
          x1={-302.514}
          y1={213.042}
          x2={-298.605}
          y2={213.042}
          gradientTransform='translate(371.063)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M72.46 213.34c-1.31-.59-2.42-1.32-2.42-1.32-.05 1.19-.11 2.41-.13 3.6 0 0-2.27-5.48-.93-5.13 1.29.33 2.48 2.36 3.48 2.85z'
          mask='url(#banner_svg__SVGID_163_)'
          fill='url(#banner_svg__SVGID_164_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_165_'
      gradientUnits='userSpaceOnUse'
      x1={-302.514}
      y1={213.042}
      x2={-298.605}
      y2={213.042}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M72.46 213.34c-1.31-.59-2.42-1.32-2.42-1.32-.05 1.19-.11 2.41-.13 3.6 0 0-2.27-5.48-.93-5.13 1.29.33 2.48 2.36 3.48 2.85z'
      fill='url(#banner_svg__SVGID_165_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_40_'
        filterUnits='userSpaceOnUse'
        x={73.14}
        y={203.72}
        width={3.13}
        height={3.07}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={73.14} y={203.72} width={3.13} height={3.07} id='banner_svg__SVGID_166_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_40_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_41_'
            filterUnits='userSpaceOnUse'
            x={73.14}
            y={203.72}
            width={3.13}
            height={3.07}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_167_'
          gradientUnits='userSpaceOnUse'
          x1={-297.924}
          y1={205.251}
          x2={-294.79}
          y2={205.251}
          gradientTransform='translate(371.063)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M76.27 203.72c-.05.18-.93 3.02-1.9 3.07-1.01.05-1.23-1.67-1.23-1.67.29.2.55.38.75.52.4.29 1.3-.55 2.38-1.92z'
          mask='url(#banner_svg__SVGID_166_)'
          fill='url(#banner_svg__SVGID_167_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_168_'
      gradientUnits='userSpaceOnUse'
      x1={-297.924}
      y1={205.251}
      x2={-294.79}
      y2={205.251}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M76.27 203.72c-.05.18-.93 3.02-1.9 3.07-1.01.05-1.23-1.67-1.23-1.67.29.2.55.38.75.52.4.29 1.3-.55 2.38-1.92z'
      fill='url(#banner_svg__SVGID_168_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_42_'
        filterUnits='userSpaceOnUse'
        x={48.37}
        y={203.16}
        width={6.74}
        height={45.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={48.37} y={203.16} width={6.74} height={45.67} id='banner_svg__SVGID_169_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_42_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_43_'
            filterUnits='userSpaceOnUse'
            x={48.37}
            y={203.16}
            width={6.74}
            height={45.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_170_'
          gradientUnits='userSpaceOnUse'
          x1={-322.691}
          y1={225.998}
          x2={-315.949}
          y2={225.998}
          gradientTransform='translate(371.063)'
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.113} stopColor='#eaeaea' />
          <stop offset={0.345} stopColor='#b3b3b3' />
          <stop offset={0.673} stopColor='#5d5d5d' />
          <stop offset={1} />
        </linearGradient>
        <path
          d='M52.2 241.79c-2.1 2.11 1.44 7.01 1.44 7.01s-.79.17-2.58-.33c-1.79-.51-2.68-1.22-2.68-1.22s.45-21.53 1.39-27.38c.37-2.3.51-4.18.59-5.97.13-2.78.13-5.36.63-8.96.09-.66.29-1.24.57-1.77 0 0-.16 12.04 2.22 12.9 2.75.99.65 1.95-.81 3.97-1.63 2.26-3.65 14.5-.57 16.37 2.72 1.67 1.9 3.27-.2 5.38z'
          mask='url(#banner_svg__SVGID_169_)'
          fill='url(#banner_svg__SVGID_170_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_171_'
      gradientUnits='userSpaceOnUse'
      x1={-322.691}
      y1={225.998}
      x2={-315.949}
      y2={225.998}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0.001} stopColor='#bfccff' />
      <stop offset={0.543} stopColor='#d4dfff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M52.2 241.79c-2.1 2.11 1.44 7.01 1.44 7.01s-.79.17-2.58-.33c-1.79-.51-2.68-1.22-2.68-1.22s.45-21.53 1.39-27.38c.37-2.3.51-4.18.59-5.97.13-2.78.13-5.36.63-8.96.09-.66.29-1.24.57-1.77 0 0-.16 12.04 2.22 12.9 2.75.99.65 1.95-.81 3.97-1.63 2.26-3.65 14.5-.57 16.37 2.72 1.67 1.9 3.27-.2 5.38z'
      fill='url(#banner_svg__SVGID_171_)'
    />
    <linearGradient
      id='banner_svg__SVGID_172_'
      gradientUnits='userSpaceOnUse'
      x1={-304.064}
      y1={229.873}
      x2={-301.988}
      y2={229.873}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M67.17 230.66l-.17-.39c1.16-.53 1.75-1.18 1.76-1.18l.32.29c-.03.02-.66.71-1.91 1.28z'
      fill='url(#banner_svg__SVGID_172_)'
    />
    <linearGradient
      id='banner_svg__SVGID_173_'
      gradientUnits='userSpaceOnUse'
      x1={-308.068}
      y1={228.492}
      x2={-303.817}
      y2={228.492}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M67.25 225.73v4.93c-.28.13-.59.25-.94.35-.95.29-2.27.28-3.32.2v-4.44l4.26-1.04z'
      fill='url(#banner_svg__SVGID_173_)'
    />
    <linearGradient
      id='banner_svg__SVGID_174_'
      gradientUnits='userSpaceOnUse'
      x1={-309.736}
      y1={230.88}
      x2={-307.983}
      y2={230.88}
      gradientTransform='translate(371.063)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M63.05 231.19c-.97-.07-1.69-.2-1.72-.2l.07-.42c.01 0 .73.13 1.68.2l-.03.42z'
      fill='url(#banner_svg__SVGID_174_)'
    />
    <path
      className='banner_svg__st108'
      d='M66.42 183.89s1.63 3.41.38 4.91c-1.24 1.5-2.91 2-3 3.03-.09 1.03.64.83.88 1.52.24.69-.17 1.44-.17 1.44s-1.39-1.46-2.03-.53c-.65.93-.67 1.84 0 2.18.67.33 1.27 0 1.32.48.05.48-1.08 1.91-3.23 1.29-2.15-.62-3.11-1.6-3.4-3.06-.29-1.46.57-3.25.57-3.25s-1.5-3.41.32-4.69c1.82-1.28 3-.67 3.86-.99.86-.32.26-1.47 1.05-1.69.8-.22 1.28 1.63 1.91 1.47.64-.16.05-2.17.54-3.03s1 .92 1 .92z'
    />
    <linearGradient
      id='banner_svg__SVGID_175_'
      gradientUnits='userSpaceOnUse'
      x1={62.029}
      y1={244.231}
      x2={65.593}
      y2={244.231}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M65.59 241.97v3.31c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21v-3.31c0 .67.8 1.21 1.78 1.21.99 0 1.78-.54 1.78-1.21z'
      fill='url(#banner_svg__SVGID_175_)'
    />
    <linearGradient
      id='banner_svg__SVGID_176_'
      gradientUnits='userSpaceOnUse'
      x1={62.029}
      y1={241.971}
      x2={65.593}
      y2={241.971}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M65.59 241.97c0 .67-.8 1.21-1.78 1.21s-1.78-.54-1.78-1.21c0-.67.8-1.21 1.78-1.21.99 0 1.78.54 1.78 1.21z'
      fill='url(#banner_svg__SVGID_176_)'
    />
    <linearGradient
      id='banner_svg__SVGID_177_'
      gradientUnits='userSpaceOnUse'
      x1={553.633}
      y1={264.764}
      x2={561.764}
      y2={264.764}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#e9f4ff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M367.44 249.86l-1.28 24.76c.01.01 0 .01 0 .01l-.47 9.2-5.67.42c-.28-2.49-.54-4.56-.68-4.91-.13-.35.19-2 .3-6.06.02-.84.03-1.77.03-2.82-.01-8.15.55-18.59 2.1-21.15.39-.63.89-1.22 1.43-1.76l.01-.01c.2-.2 2.71-2.07 2.92-2.26l1.31 4.58z'
      fill='url(#banner_svg__SVGID_177_)'
    />
    <linearGradient
      id='banner_svg__SVGID_178_'
      gradientUnits='userSpaceOnUse'
      x1={561.764}
      y1={265.695}
      x2={573.996}
      y2={265.695}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#e9f4ff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M379.68 283.95l-2.91 3.55-8.34-4.34-.24-8-.74-25.31.98-3.27 2.45-2.7s4.58.51 6.84 4.15c2.27 3.65.5 21.37 1.75 33.36.08.92.15 1.77.21 2.56z'
      fill='url(#banner_svg__SVGID_178_)'
    />
    <path
      className='banner_svg__st204'
      d='M367.44 249.86s1.91-.09 2.62 1.03c.71 1.12.85 1.9.85 1.9s.81-8.2-.05-8.89l-2.45 2.7-.97 3.26zM367.44 249.86s-1.32-.24-2.11.33-1.07 1.32-1.07 1.32.58-6.02 1.89-6.21l.71 2.26.58 2.3z'
    />
    <linearGradient
      id='banner_svg__SVGID_179_'
      gradientUnits='userSpaceOnUse'
      x1={560.298}
      y1={245.477}
      x2={565.19}
      y2={245.477}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M370.5 241.1l.37 2.79s-.01 2.99-1.3 4.35-2.12 1.62-2.12 1.62-1.73-1.05-1.43-3.29c.29-2.23.2-3.45.2-3.45l1.27-.82 3.01-1.2z'
      fill='url(#banner_svg__SVGID_179_)'
    />
    <linearGradient
      id='banner_svg__SVGID_180_'
      gradientUnits='userSpaceOnUse'
      x1={554.776}
      y1={238.715}
      x2={565.237}
      y2={238.715}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M360.46 236.37s.12 4.37 2.68 7.02c2.56 2.65 3.84 2.11 3.84 2.11s3.62-2.73 3.93-5.98c.31-3.25-3.94-7.64-3.94-7.64l-3.98.14-2.53 4.35z'
      fill='url(#banner_svg__SVGID_180_)'
    />
    <path
      className='banner_svg__st108'
      d='M359.16 229.53c-.79.93-1.3 2.08-1.38 3.3-.1 1.66.63 2.59 2.67 3.54 2.72 1.26 5.26-.93 5.82.25s.98 2.33.98 2.33.52-2.74 1.53-2.07c1.01.67.28 3.71 1.71 4.22s3.65-6.55.91-9.72-5.39-1.8-6.84-1.62c-1.45.17-1.2-2.54-2.9-2.14-.89.21-1.82 1.11-2.5 1.91z'
    />
    <linearGradient
      id='banner_svg__SVGID_181_'
      gradientUnits='userSpaceOnUse'
      x1={550.739}
      y1={260.41}
      x2={557.538}
      y2={260.41}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={0.045} stopColor='#b0cdff' />
      <stop offset={0.261} stopColor='#c9ddff' />
      <stop offset={0.485} stopColor='#dbe8ff' />
      <stop offset={0.724} stopColor='#e6efff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M363.22 247.55c-.55.54-1.05 1.12-1.43 1.76-1.56 2.55-2.11 13-2.1 21.15 0 1.05-.01 1.98-.03 2.82l-1.69-.01s-2.66-2.5-.99-5.88c1.66-3.38 1.05-9.32 1.58-13.79.49-4.33 4.41-5.96 4.66-6.05z'
      fill='url(#banner_svg__SVGID_181_)'
    />
    <linearGradient
      id='banner_svg__SVGID_182_'
      gradientUnits='userSpaceOnUse'
      x1={560.481}
      y1={262.512}
      x2={562.505}
      y2={262.512}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M368.19 275.17l-1.85-.25s-.06-.11-.17-.29c0 0 .01 0 0-.01l1.28-24.76.74 25.31z'
      fill='url(#banner_svg__SVGID_182_)'
    />
    <path className='banner_svg__st13' d='M300.91 261.34l48.24 27.85 24.12-13.93-48.24-27.85z' />
    <path className='banner_svg__st204' d='M373.27 275.26l16.08 9.29v45.92l-40.2 23.71v-64.99z' />
    <linearGradient
      id='banner_svg__SVGID_183_'
      gradientUnits='userSpaceOnUse'
      x1={495.233}
      y1={307.757}
      x2={543.474}
      y2={307.757}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_183_)' d='M300.91 261.34v64.98l48.24 27.86v-64.99z' />
    <path className='banner_svg__st13' d='M341.11 330.97v9.28l-8.04-4.64v-9.29z' />
    <linearGradient
      id='banner_svg__SVGID_184_'
      gradientUnits='userSpaceOnUse'
      x1={504.163}
      y1={245.994}
      x2={528.86}
      y2={245.994}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_184_)' d='M334.54 242.43v21.39l-24.7-14.26v-21.39z' />
    <linearGradient
      id='banner_svg__SVGID_185_'
      gradientUnits='userSpaceOnUse'
      x1={528.86}
      y1={252.781}
      x2={530.234}
      y2={252.781}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_185_)' d='M334.54 263.82l1.37-.69v-21.39l-1.37.69z' />
    <linearGradient
      id='banner_svg__SVGID_186_'
      gradientUnits='userSpaceOnUse'
      x1={505.325}
      y1={261.81}
      x2={521.523}
      y2={261.81}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_186_)' d='M327.2 261.81l-8.09 4.68-8.1-4.68 8.1-4.68z' />
    <path className='banner_svg__st13' d='M335.91 241.74l-24.69-14.26-1.38.69 24.7 14.26z' />
    <linearGradient
      id='banner_svg__SVGID_187_'
      gradientUnits='userSpaceOnUse'
      x1={510.328}
      y1={252.233}
      x2={519.6}
      y2={252.233}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_187_)' d='M325.28 244.21l-6.17-3.56-3.1 12.48v7.12l6.18 3.57.04-7.44z' />
    <path className='banner_svg__st13' d='M322.19 263.82l.69-.43.03-7.43-.68.42z' />
    <path
      className='banner_svg__st13'
      d='M322.91 255.96l3.06-12.17-.69.42-3.05 12.17zM325.97 243.79l-6.18-3.57-.68.43 6.17 3.56z'
    />
    <linearGradient
      id='banner_svg__SVGID_188_'
      gradientUnits='userSpaceOnUse'
      x1={513.425}
      y1={264.599}
      x2={521.523}
      y2={264.599}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_188_)' d='M327.2 261.81v.9l-8.09 4.68v-.9z' />
    <linearGradient
      id='banner_svg__SVGID_189_'
      gradientUnits='userSpaceOnUse'
      x1={505.325}
      y1={264.598}
      x2={513.425}
      y2={264.598}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_189_)' d='M311.01 261.81v.9l8.1 4.68v-.9z' />
    <linearGradient
      id='banner_svg__SVGID_190_'
      gradientUnits='userSpaceOnUse'
      x1={526.423}
      y1={273.883}
      x2={558.581}
      y2={273.883}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_190_)' d='M364.26 273.88l-16.08 9.29-16.08-9.29 16.08-9.28z' />
    <linearGradient
      id='banner_svg__SVGID_191_'
      gradientUnits='userSpaceOnUse'
      x1={551.509}
      y1={326.325}
      x2={567.595}
      y2={326.325}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_191_)' d='M373.27 312.4v18.56l-16.08 9.29v-18.57z' />
    <linearGradient
      id='banner_svg__SVGID_192_'
      gradientUnits='userSpaceOnUse'
      x1={540.22}
      y1={268.87}
      x2={544.872}
      y2={268.87}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M345.9 268.87c0 .87 1.04 1.58 2.32 1.58 1.29 0 2.33-.71 2.33-1.58 0-.87-1.04-1.58-2.33-1.58-1.28 0-2.32.71-2.32 1.58z'
      fill='url(#banner_svg__SVGID_192_)'
    />
    <linearGradient
      id='banner_svg__SVGID_193_'
      gradientUnits='userSpaceOnUse'
      x1={547.63}
      y1={273.265}
      x2={552.282}
      y2={273.265}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M353.31 273.26c0 .87 1.04 1.58 2.32 1.58 1.29 0 2.33-.71 2.33-1.58 0-.87-1.04-1.58-2.33-1.58-1.28.01-2.32.71-2.32 1.58z'
      fill='url(#banner_svg__SVGID_193_)'
    />
    <linearGradient
      id='banner_svg__SVGID_194_'
      gradientUnits='userSpaceOnUse'
      x1={529.53}
      y1={333.287}
      x2={532.737}
      y2={333.287}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M335.45 331.83c-.21.3-.28.72-.22 1.15a2.779 2.779 0 00.74 1.52c.75.81 1.74.91 2.21.24.46-.67.23-1.86-.52-2.67-.76-.8-1.75-.91-2.21-.24z'
      fill='url(#banner_svg__SVGID_194_)'
    />
    <g>
      <path
        className='banner_svg__st204'
        d='M330.5 352.26v-16.02l5.97-2.91-.38-.76-6.44 3.14v15.08L313 341.17l-.02-78.21 13.81-7.96-.43-.73-14.22 8.2.01 79.19z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_195_'
      gradientUnits='userSpaceOnUse'
      x1={564.203}
      y1={260.661}
      x2={578.843}
      y2={260.661}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={0.045} stopColor='#b0cdff' />
      <stop offset={0.261} stopColor='#c9ddff' />
      <stop offset={0.485} stopColor='#dbe8ff' />
      <stop offset={0.724} stopColor='#e6efff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M374.03 274.74l-.71-.61-2.93-2.49-.5-.43s6.18-6.62 7.9-9.32c1.72-2.7-2.31-4.11-4.35-9.46-2.04-5.34 1.09-5.77 1.09-5.77s2.09-.69 4.13 1.83c1.44 1.78 3.61 4.6 5.64 10.06 2.03 5.47-10.27 16.19-10.27 16.19z'
      fill='url(#banner_svg__SVGID_195_)'
    />
    <linearGradient
      id='banner_svg__SVGID_196_'
      gradientUnits='userSpaceOnUse'
      x1={560.553}
      y1={274.555}
      x2={567.635}
      y2={274.555}
      gradientTransform='translate(-194.32)'
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M373.32 274.13c-.21.17-.49.35-.87.53-1.27.6-1.93.75-2.42 1.38-.49.64-.77 1.43-.77 1.43s-3.67-1.12-2.92-2.55c.6-1.15 2.47-2.7 4.05-3.27l2.93 2.48z'
      fill='url(#banner_svg__SVGID_196_)'
    />
    <linearGradient
      id='banner_svg__SVGID_197_'
      gradientUnits='userSpaceOnUse'
      x1={53.852}
      y1={92.197}
      x2={125.213}
      y2={92.197}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M125.21 38.62c-.38-.22-.82-.33-1.26-.32-.39.01-.79.11-1.18.33l-.76.44-63.82 36.85a3.973 3.973 0 00-1.99 3.44l-.01 65.2v1.14c0 .35.07.68.19.97.16.39.42.73.73.98l-1.93-1.29-.01-.01c-.77-.42-1.33-1.22-1.33-2.22l.01-66.34c0-1.42.76-2.73 1.99-3.44l64.58-37.29c.84-.49 1.8-.41 2.52.04l.13.09 2.14 1.43z'
      fill='url(#banner_svg__SVGID_197_)'
    />
    <path
      className='banner_svg__st13'
      d='M126.47 106.65c0 1.78-.95 3.42-2.49 4.31l-63.99 36.93c-.81.47-1.73.43-2.45.04l-.41-.27c-.32-.26-.57-.59-.73-.98-.12-.29-.19-.62-.19-.97v-1.14l.01-65.2c0-1.42.76-2.73 1.99-3.44l63.81-36.85.76-.44c.38-.22.78-.33 1.18-.33.45-.01.89.11 1.26.32l.02.01c.72.41 1.23 1.18 1.23 2.12v65.89z'
    />
    <linearGradient
      id='banner_svg__SVGID_198_'
      gradientUnits='userSpaceOnUse'
      x1={68.176}
      y1={102.013}
      x2={77.41}
      y2={102.013}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_198_)' d='M68.18 90.47v28.42l9.23-5.32V85.14z' />
    <linearGradient
      id='banner_svg__SVGID_199_'
      gradientUnits='userSpaceOnUse'
      x1={86.643}
      y1={87.8}
      x2={95.877}
      y2={87.8}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_199_)' d='M86.65 108.23l-.01-35.53 9.24-5.33v35.54z' />
    <linearGradient
      id='banner_svg__SVGID_200_'
      gradientUnits='userSpaceOnUse'
      x1={105.111}
      y1={87.797}
      x2={114.347}
      y2={87.797}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_200_)' d='M105.11 97.57V83.35l9.24-5.32v14.21z' />
    <linearGradient
      id='banner_svg__SVGID_201_'
      gradientUnits='userSpaceOnUse'
      x1={70.026}
      y1={125.825}
      x2={76.936}
      y2={125.825}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_201_)' d='M70.03 125.83v3.98l6.91-3.98-.01-3.99z' />
    <linearGradient
      id='banner_svg__SVGID_202_'
      gradientUnits='userSpaceOnUse'
      x1={89.724}
      y1={114.649}
      x2={96.634}
      y2={114.649}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_202_)' d='M89.72 114.65v3.99l6.91-3.99v-3.99z' />
    <linearGradient
      id='banner_svg__SVGID_203_'
      gradientUnits='userSpaceOnUse'
      x1={109.421}
      y1={105.267}
      x2={116.332}
      y2={105.267}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_203_)' d='M109.42 105.27v3.98l6.91-3.98v-3.99z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_44_'
        filterUnits='userSpaceOnUse'
        x={403.47}
        y={198.28}
        width={2.53}
        height={98.43}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={403.47} y={198.28} width={2.53} height={98.43} id='banner_svg__SVGID_204_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_44_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_45_'
            filterUnits='userSpaceOnUse'
            x={403.47}
            y={198.28}
            width={2.53}
            height={98.43}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_205_'
          gradientUnits='userSpaceOnUse'
          x1={403.467}
          y1={247.501}
          x2={405.993}
          y2={247.501}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M403.47 198.28s.87 50.75 1.26 98.43c0 0 .89-76.8 1.26-98.43h-2.52z'
          mask='url(#banner_svg__SVGID_204_)'
          fill='url(#banner_svg__SVGID_205_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_206_'
      gradientUnits='userSpaceOnUse'
      x1={403.467}
      y1={247.501}
      x2={405.993}
      y2={247.501}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M403.47 198.28s.87 50.75 1.26 98.43c0 0 .89-76.8 1.26-98.43h-2.52z' fill='url(#banner_svg__SVGID_206_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_46_'
        filterUnits='userSpaceOnUse'
        x={395.38}
        y={181.54}
        width={18.69}
        height={18.69}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={395.38} y={181.54} width={18.69} height={18.69} id='banner_svg__SVGID_207_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_46_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_47_'
            filterUnits='userSpaceOnUse'
            x={395.38}
            y={181.54}
            width={18.69}
            height={18.69}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_208_'
          gradientUnits='userSpaceOnUse'
          x1={414.078}
          y1={190.886}
          x2={395.383}
          y2={190.886}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={404.73}
          cy={190.89}
          r={9.35}
          mask='url(#banner_svg__SVGID_207_)'
          fill='url(#banner_svg__SVGID_208_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_209_'
      gradientUnits='userSpaceOnUse'
      x1={414.078}
      y1={190.886}
      x2={395.383}
      y2={190.886}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={404.73} cy={190.89} r={9.35} fill='url(#banner_svg__SVGID_209_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_48_'
        filterUnits='userSpaceOnUse'
        x={141.14}
        y={308.74}
        width={2.53}
        height={64}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={141.14} y={308.74} width={2.53} height={64} id='banner_svg__SVGID_210_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_48_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_49_'
            filterUnits='userSpaceOnUse'
            x={141.14}
            y={308.74}
            width={2.53}
            height={64}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_211_'
          gradientUnits='userSpaceOnUse'
          x1={141.138}
          y1={340.742}
          x2={143.664}
          y2={340.742}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M141.14 308.74s.87 33 1.26 64c0 0 .89-49.94 1.26-64h-2.52z'
          mask='url(#banner_svg__SVGID_210_)'
          fill='url(#banner_svg__SVGID_211_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_212_'
      gradientUnits='userSpaceOnUse'
      x1={141.138}
      y1={340.742}
      x2={143.664}
      y2={340.742}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M141.14 308.74s.87 33 1.26 64c0 0 .89-49.94 1.26-64h-2.52z' fill='url(#banner_svg__SVGID_212_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_50_'
        filterUnits='userSpaceOnUse'
        x={133.05}
        y={292}
        width={18.69}
        height={18.69}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={133.05} y={292} width={18.69} height={18.69} id='banner_svg__SVGID_213_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_50_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_51_'
            filterUnits='userSpaceOnUse'
            x={133.05}
            y={292}
            width={18.69}
            height={18.69}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_214_'
          gradientUnits='userSpaceOnUse'
          x1={151.748}
          y1={301.343}
          x2={133.054}
          y2={301.343}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={142.4}
          cy={301.34}
          r={9.35}
          mask='url(#banner_svg__SVGID_213_)'
          fill='url(#banner_svg__SVGID_214_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_215_'
      gradientUnits='userSpaceOnUse'
      x1={151.748}
      y1={301.343}
      x2={133.054}
      y2={301.343}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={142.4} cy={301.34} r={9.35} fill='url(#banner_svg__SVGID_215_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_52_'
        filterUnits='userSpaceOnUse'
        x={27.52}
        y={143.56}
        width={2.53}
        height={98.43}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={27.52} y={143.56} width={2.53} height={98.43} id='banner_svg__SVGID_216_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_52_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_53_'
            filterUnits='userSpaceOnUse'
            x={27.52}
            y={143.56}
            width={2.53}
            height={98.43}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_217_'
          gradientUnits='userSpaceOnUse'
          x1={27.519}
          y1={192.777}
          x2={30.045}
          y2={192.777}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M27.52 143.56s.87 50.75 1.26 98.43c0 0 .89-76.8 1.26-98.43h-2.52z'
          mask='url(#banner_svg__SVGID_216_)'
          fill='url(#banner_svg__SVGID_217_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_218_'
      gradientUnits='userSpaceOnUse'
      x1={27.519}
      y1={192.777}
      x2={30.045}
      y2={192.777}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M27.52 143.56s.87 50.75 1.26 98.43c0 0 .89-76.8 1.26-98.43h-2.52z' fill='url(#banner_svg__SVGID_218_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_54_'
        filterUnits='userSpaceOnUse'
        x={19.43}
        y={126.81}
        width={18.69}
        height={18.69}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={19.43} y={126.81} width={18.69} height={18.69} id='banner_svg__SVGID_219_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_54_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_55_'
            filterUnits='userSpaceOnUse'
            x={19.43}
            y={126.81}
            width={18.69}
            height={18.69}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_220_'
          gradientUnits='userSpaceOnUse'
          x1={38.129}
          y1={136.162}
          x2={19.435}
          y2={136.162}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={28.78}
          cy={136.16}
          r={9.35}
          mask='url(#banner_svg__SVGID_219_)'
          fill='url(#banner_svg__SVGID_220_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_221_'
      gradientUnits='userSpaceOnUse'
      x1={38.129}
      y1={136.162}
      x2={19.435}
      y2={136.162}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={28.78} cy={136.16} r={9.35} fill='url(#banner_svg__SVGID_221_)' />
  </svg>
)

export default SvgBanner
