import React from 'react'

const SvgOneKilometerFeature3 = props => (
  <svg id='one-kilometer-feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.one-kilometer-feature3_svg__st0{fill:#334966}'}</style>
    <g id='one-kilometer-feature3_svg__ic_\u4E1A\u52A1\u98CE\u9669\u9632\u62A4'>
      <path
        id='one-kilometer-feature3_svg__Fill-6_6_'
        className='one-kilometer-feature3_svg__st0'
        d='M25 1l-2.18 1.19A49.692 49.692 0 014 8v21.53c0 5.21 2.89 9.99 7.5 12.4L25 49l13.5-7.07c4.61-2.42 7.5-7.19 7.5-12.4V8c-6.6-.66-13-2.64-18.82-5.81L25 1zm0 2.28l1.22.66A51.278 51.278 0 0044 9.78v19.75c0 4.48-2.47 8.55-6.43 10.63L25 46.74l-12.57-6.58A11.979 11.979 0 016 29.53V9.78a51.07 51.07 0 0017.78-5.84L25 3.28z'
      />
      <path
        id='one-kilometer-feature3_svg__\u8DEF\u5F84_2_'
        d='M26 12v1.04c6.16.51 11 5.67 11 11.96v1H13v-1c0-6.29 4.84-11.45 11-11.96V12h2z'
        fill='#fd5c1f'
      />
      <path
        id='one-kilometer-feature3_svg__\u8DEF\u5F84_1_'
        className='one-kilometer-feature3_svg__st0'
        d='M24 26h2v7c0 1.71-1.61 3-3.5 3-1.82 0-3.39-1.2-3.49-2.82L19 33v-1h2v1c0 .5.63 1 1.5 1 .82 0 1.42-.44 1.49-.91L24 33v-7z'
      />
    </g>
  </svg>
)

export default SvgOneKilometerFeature3
