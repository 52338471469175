import React from 'react'

const SvgOneKilometerFeature4 = props => (
  <svg id='one-kilometer-feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.one-kilometer-feature4_svg__st1{fill:#fd5c1f}'}</style>
    <g id='one-kilometer-feature4_svg__\u6570\u636E\u5B9E\u65F6\u540C\u6B65-48x48'>
      <path
        id='one-kilometer-feature4_svg__Combined-Shape_6_'
        d='M5.8 35.75l5.06.56-4.19 4.19C-1.32 31.07-.87 16.93 8.03 8.03S31.07-1.32 40.5 6.67l-1.42 1.42C30.44.88 17.56 1.33 9.44 9.44 2.32 16.57 1.1 27.37 5.8 35.75zm38.4-21.5l-5.06-.56 4.18-4.18c7.99 9.43 7.54 23.57-1.36 32.47S18.93 51.32 9.5 43.33l1.42-1.42c8.64 7.21 21.52 6.76 29.64-1.35 7.12-7.13 8.34-17.93 3.64-26.31z'
        fill='#334966'
      />
      <path
        id='one-kilometer-feature4_svg__Rectangle-76'
        className='one-kilometer-feature4_svg__st1'
        d='M16 16h18v4H16z'
      />
      <path
        id='one-kilometer-feature4_svg__Rectangle-76-Copy'
        className='one-kilometer-feature4_svg__st1'
        d='M16 23h18v4H16z'
      />
      <path
        id='one-kilometer-feature4_svg__Rectangle-76-Copy-2'
        className='one-kilometer-feature4_svg__st1'
        d='M16 30h18v4H16z'
      />
    </g>
  </svg>
)

export default SvgOneKilometerFeature4
