import React, { useRef } from 'react'
import { useHover } from '@raysync/common/hooks'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper, H3 } from '@raysync/common/components'
import Layout from '../../components/layout'

import { ApplicationSection, ApplicationContainer, ArchSection } from './atoms'
import { ApplicationItem, ArchTab, ProcessItem, ProcessContainer } from './one-kilometer.atoms'
import {
  WarpBigBox,
  ImgBigBox,
  WrapSmallBox,
  SmallBoxOne,
  SmallBoxTwo,
  SmallItemBoxTwo,
} from './enterprise-interconnection.atoms'

import Feature1 from '@raysync/common/components/svg/network/one-kilometer/one-kilometer-feature1'
import Feature2 from '@raysync/common/components/svg/network/one-kilometer/one-kilometer-feature2'
import Feature3 from '@raysync/common/components/svg/network/one-kilometer/one-kilometer-feature3'
import Feature4 from '@raysync/common/components/svg/network/one-kilometer/one-kilometer-feature4'

import Banner from '@raysync/common/components/svg/network/one-kilometer/banner'

import Internet1 from '@raysync/common/components/svg/network/one-kilometer/company'
import Internet2 from '@raysync/common/components/svg/network/one-kilometer/store'

import Img1 from '../../images/network/one-kilometer/connect_01.png'
import Img2 from '../../images/network/one-kilometer/connect_02.png'
import Img3 from '../../images/network/one-kilometer/connect_03.png'

const MainFuncItem = ({ onTrigger, children, active }) => {
  const ref = useRef()
  useHover(ref, onTrigger, true)
  return (
    <ProcessItem ref={ref} active={active}>
      {children}
    </ProcessItem>
  )
}

class OneKilometer extends React.PureComponent {
  state = {
    //方案优势列表
    featureList: [
      {
        title: intl.get('network4.feature.item1.title'),
        desc: intl.get('network4.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('network4.feature.item2.title'),
        desc: intl.get('network4.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('network4.feature.item3.title'),
        desc: intl.get('network4.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('network4.feature.item4.title'),
        desc: intl.get('network4.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    //应用场景列表1
    InternetThree: [
      {
        desc: intl.get('network4.feature.item5.desc1'),
        desc2: intl.get('network4.feature.item5.desc5'),
      },
      {
        desc: intl.get('network4.feature.item5.desc2'),
        desc2: intl.get('network4.feature.item5.desc6'),
      },
    ],
    //应用场景列表2
    InternetFour: [
      {
        desc: intl.get('network4.feature.item5.desc3'),
        desc2: intl.get('network4.feature.item5.desc7'),
      },
      {
        desc: intl.get('network4.feature.item5.desc4'),
        desc2: intl.get('network4.feature.item5.desc8'),
      },
      {
        desc: '',
        desc2: intl.get('network4.feature.item5.desc9'),
      },
    ],
    activedArchTab: 'tab1',
    activedTab: 'TabBox1',
    activedImg: Img1,
    active: 0,
    funcList: [
      {
        title: intl.get('network4.func.item1.title'),
        desc: intl.get('network4.func.item1.desc'),
        img: Img1,
      },
      {
        title: intl.get('network4.func.item2.title'),
        desc: intl.get('network4.func.item2.desc'),
        img: Img2,
      },
      {
        title: intl.get('network4.func.item3.title'),
        desc: intl.get('network4.func.item3.desc'),
        img: Img3,
      },
    ],
  }

  handleTabClick(activedArchTab) {
    this.setState({ activedArchTab })
  }

  handleTrigger(index) {
    this.setState({ activedImg: this.state.funcList[index].img })
    this.setState({ active: index })
  }

  componentWillReceiveProps() {
    this.setState({ active: 0 })
  }

  render() {
    const { location } = this.props
    const { featureList, activedArchTab, InternetThree, InternetFour, activedImg, funcList, active } = this.state

    return (
      <Layout pageType='network4' location={location} scaled>
        {/* 第一屏(最后一公里解决方案) */}
        <Swiper>
          <SolutionBanner type='network4' pic={<Banner />} />
        </Swiper>

        {/* 第二屏(方案架构) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network4.arch.title')}</CommonTitle>
            <ProcessContainer>
              <div>
                {funcList.map((l, i) => (
                  <MainFuncItem key={i} onTrigger={this.handleTrigger.bind(this, i)} active={active === i}>
                    <div>
                      <h4>{l.title}</h4>
                      <p>{l.desc}</p>
                    </div>
                  </MainFuncItem>
                ))}
              </div>
              <img src={activedImg} alt='feature' />
            </ProcessContainer>
          </Wrapper>
        </ArchSection>

        {/* 第三屏(方案优势) */}
        <ApplicationSection>
          <Wrapper>
            <CommonTitle>{intl.get('network4.feature.title')}</CommonTitle>
            <ApplicationContainer>
              {featureList.map((l, i) => (
                <ApplicationItem key={i}>
                  <div>{l.svg}</div>
                  <div>
                    <H3>{l.title}</H3>
                    <p>{l.desc}</p>
                  </div>
                </ApplicationItem>
              ))}
            </ApplicationContainer>
          </Wrapper>
        </ApplicationSection>

        {/* 第四屏(应用场景) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle>{intl.get('network4.feature.title4')}</CommonTitle>
            <ArchTab active={activedArchTab === 'tab1'} onClick={this.handleTabClick.bind(this, 'tab1')}>
              {intl.get('network4.feature.title2')}
            </ArchTab>
            <ArchTab active={activedArchTab === 'tab2'} onClick={this.handleTabClick.bind(this, 'tab2')}>
              {intl.get('network4.feature.title3')}
            </ArchTab>
            <WarpBigBox>
              <ImgBigBox>{activedArchTab === 'tab1' ? <Internet1 /> : <Internet2 />}</ImgBigBox>
              <WrapSmallBox>
                <SmallBoxOne>
                  <SmallItemBoxTwo>
                    <H3>{intl.get('network4.feature.item5.title1')}</H3>
                    {InternetThree.map((l, i) => (
                      <div key={i}>
                        <p>{activedArchTab === 'tab1' ? l.desc : l.desc2}</p>
                      </div>
                    ))}
                  </SmallItemBoxTwo>
                </SmallBoxOne>
                <SmallBoxTwo>
                  <SmallItemBoxTwo>
                    <H3>{intl.get('network4.feature.item5.title2')}</H3>
                    {InternetFour.map((l, i) => (
                      <div key={i}>
                        <p>{activedArchTab === 'tab1' ? l.desc : l.desc2}</p>
                      </div>
                    ))}
                  </SmallItemBoxTwo>
                </SmallBoxTwo>
              </WrapSmallBox>
            </WarpBigBox>
          </Wrapper>
        </ArchSection>

        {/* 第五屏(申请免费试用) */}
        <Swiper>
          <InviteBanner type='network4' />
        </Swiper>
      </Layout>
    )
  }
}

export default OneKilometer
