import React from 'react'

const SvgOneKilometerFeature1 = props => (
  <svg id='one-kilometer-feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.one-kilometer-feature1_svg__st1{fill:#fd5c1f}.one-kilometer-feature1_svg__st2{fill:#2b4b67}'}</style>
    <path
      d='M43.52 11.45L27.48 2.57c-1.36-.76-3.59-.75-4.96 0L6.48 11.45C5.09 12.22 4 14.04 4 15.6v18.79c0 1.56 1.09 3.38 2.48 4.15l16.05 8.89c.68.36 1.55.57 2.47.57s1.79-.21 2.47-.58l16.05-8.89c1.39-.77 2.48-2.59 2.48-4.15V15.6c0-1.56-1.09-3.38-2.48-4.15z'
      fill='none'
      stroke='#2b4b67'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      className='one-kilometer-feature1_svg__st1'
      d='M28.54 8.86l-3.1-1.81c-.25-.14-.65-.14-.89 0l-3.1 1.82c-.28.16-.45.45-.45.78v3.63c0 .33.17.63.46.8l3.12 1.82a.848.848 0 00.84 0l3.12-1.82a.94.94 0 00.46-.8V9.65c0-.32-.17-.63-.46-.79z'
    />
    <path
      className='one-kilometer-feature1_svg__st2'
      d='M25 18.09c-.44 0-.8.37-1 .82v7.46c.2.45.56.82 1 .82.44 0 .8-.37 1-.82v-7.46c-.2-.45-.56-.82-1-.82zM37.3 26.31c.14 0 .27-.03.4-.11.38-.22.52-.72.3-1.12-.16-.29-2.47-4.8-9.11-8.55a.79.79 0 00-1.09.32c-.21.4-.07.89.31 1.11 6.24 3.52 8.45 7.86 8.49 7.93a.8.8 0 00.7.42zM20.11 16.53c-6.64 3.75-8.94 8.26-9.11 8.55-.22.39-.08.89.3 1.12.12.07.26.11.4.11.28 0 .55-.15.7-.41.04-.07 2.25-4.41 8.49-7.93.39-.22.53-.72.31-1.11-.22-.4-.7-.54-1.09-.33z'
    />
    <path className='one-kilometer-feature1_svg__st1' d='M36 29h6v6h-6zM22 29h6v6h-6zM8 29h6v6H8z' />
  </svg>
)

export default SvgOneKilometerFeature1
